import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addToast, loginInitiated, Toast } from '~store/user/user.actions'
import {
  Button,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Grid,
  Theme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import { LoginRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

interface Props {
  isOpenCallback: (e: boolean) => void
  resetCallback: (e: boolean) => void
  login: string
  password: string
  createNewAllowed: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(2),
    },
    width: {
      width: '400px',
      margin: '10px 0',
    },
  }),
)

const TwoFactorAuthentification: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])
  const dispatchGetLogin = useCallback((loginParams: LoginRequest) => dispatch(loginInitiated(loginParams)), [dispatch])

  const [secretCode, setSecretCode] = useState<string>('')

  const _onActivateCode = () => {
    props.isOpenCallback(false)
    if (secretCode) {
      const loginRequest: LoginRequest = {
        login: props.login,
        password: props.password,
        oneTimePassword: secretCode,
      }
      dispatchGetLogin(loginRequest)
    } else {
      const toast: Toast = {
        type: Severity.error,
        text: t('twoFactorAuthentification:securityCodeError'),
      }
      dispatchAddToast(toast)
    }
  }

  const _onKeyPress = (event) => {
    if (event.key === 'Enter') {
      _onActivateCode()
    }
  }

  const _onResetCode = () => {
    props.resetCallback(true)
  }
  const _onCancel = () => {
    history.push('/login')
    props.isOpenCallback(false)
  }

  return (
    <>
      <DialogTitle>
        {t('twoFactorAuthentification:headline')}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          className={classes.width}
        >
          <Grid item xs={12}>
            <TextField
              className={`${classes.width}`}
              color='primary'
              value={secretCode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSecretCode(event.target.value) }}
              label={t('twoFactorAuthentification:activateTwoFactorAuthentificationSecurityCode')}
              autoFocus
              onKeyPress={_onKeyPress}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          onClick={_onCancel}
        >
          {t('common:cancel')}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={_onActivateCode}
        >
          {t('login:login')}
        </Button>
        {
          props.createNewAllowed &&
            <Button
              color='primary'
              variant='contained'
              onClick={_onResetCode}
            >
              {t('twoFactorAuthentification:reset2FA')}
            </Button>
        }
      </DialogActions>
    </>
  )
}

export default TwoFactorAuthentification
