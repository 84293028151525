import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from '@mui/material'
import './navigation.scss'

interface NavigationHeaderProps {
  collapsed: boolean
  toggle: () => void
  collapsedImage?: string
  expandedImage?: string
}

const NavigationHeader = (props: NavigationHeaderProps) => {
  const toggleProps = props.toggle
  const toggle = useCallback(
    () => {
      toggleProps()
    },
    [toggleProps],
  )
  return (
    <div className='navItem toggle'>
      <div
        className='toggle'
        onClick={toggle}
      >
        <div className='navItem__image'>
          <Icon className='icon'>
            {props.collapsed
              ? 'menu'
              : 'close'}
          </Icon>
        </div>
      </div>
      <Link to='/home' className='navItem__logo'>
        <div>
          <img
            src={props.expandedImage}
            className={props.collapsed ? 'hidden' : ''}
            alt=''
          />
          <img
            src={props.collapsedImage}
            alt=''
            className={!props.collapsed ? 'hidden' : ''}
          />
        </div>
      </Link>
    </div>
  )
}

export default NavigationHeader
