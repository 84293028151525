import React, { useEffect } from 'react'

import { Checkbox, Collapse, IconButton, TableCell, TableRow, Theme } from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import {
  DoorInfoDto,
  DoorProtocolDto,
  UserRoleDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { makeStyles } from '@mui/styles'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { format } from 'date-fns'
import { appLayout } from '~screens/App/App'
import ActionItem from '~shared/components/TableView/ActionItem'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUser } from '~store/user/user.selector'

interface Props {
  door: DoorInfoDto
  index: number
  exportDoor?: boolean
  changeExportDoor?: (value: boolean) => void
  onSelectDoor?: (door: DoorInfoDto) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    border: '0',
    height: '50px',
  },
  dark: {
    backgroundColor: theme.palette.grey.A100,
  },
  logContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logDate: {
    width: '120px',
  },
  collapseBackground: {
    backgroundColor: theme.palette.grey.A200,
  },
  headline: {
    fontWeight: 'bold',
    marginRight: theme.spacing(),
  },
}))

const TableRowView: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const offsetInSeconds = new Date().getTimezoneOffset() * 60 * 1000

  const user = useSelector(getUser)

  const lang = localStorage.getItem('language')

  const dateFormat: string = lang && appLayout && appLayout.format && appLayout.format.date ? appLayout.format.date[lang] : 'dd.MM.yyyy'
  const timeFormat: string = lang && appLayout && appLayout.format && appLayout.format.time ? appLayout.format.time[lang] : 'HH:mm'

  const [door, setDoor] = React.useState<DoorInfoDto>(props.door)
  const [open, setOpen] = React.useState<boolean>(false)

  const [exportDoor, setExportDoor] = React.useState<boolean>(props.exportDoor ? props.exportDoor : false)

  useEffect(() => {
    setExportDoor(props.exportDoor ? props.exportDoor : false)
  }, [props.exportDoor])

  useEffect(() => {
    props.door && setDoor(props.door)
  }, [props.door])

  useEffect(() => {
    props.changeExportDoor && props.changeExportDoor(exportDoor)
  }, [exportDoor])

  const getLastProtocol = (door: DoorInfoDto) => {
    const sortDoor = { ...door }
    const protocols: DoorProtocolDto[] = [...sortDoor.protocols]
    if (protocols.length > 1) {
      protocols.sort((a, b) => (b.createdAt).getTime() - (a.createdAt).getTime())
      return protocols[0]
    } else {
      if (protocols.length === 1) {
        return protocols[0]
      }
    }
  }

  const lastProtocol: any = getLastProtocol(door)

  const darkTableRow = (props.index % 2 === 0) ? classes.dark : ''
  return (
    <>
      <TableRow key={props.index} className={`${darkTableRow} ${classes.row}`}>
        {user && user.role === UserRoleDto.TechUser &&
          <TableCell style={{ borderBottom: '0px' }} padding='checkbox'>
            <Checkbox
              onChange={() => setExportDoor(!exportDoor)}
              checked={exportDoor}
            />
          </TableCell>}
        <TableCell style={{ borderBottom: '0px' }}>
          {door.protocols.length > 0 &&
            <IconButton color={open ? 'primary' : 'secondary'} aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>}
        </TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.customer}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.shortId}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.name}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.description}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.city}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.building}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.buildingPart}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>{door.floor}</TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>
          {lastProtocol && format((new Date(lastProtocol.createdAt).getTime() - offsetInSeconds), `${dateFormat} ${timeFormat}`)}
        </TableCell>
        <TableCell align='left' style={{ borderBottom: '0px' }}>
          {lastProtocol && lastProtocol.user}
        </TableCell>
        {user && user.role === UserRoleDto.TechUser &&
          <TableCell align='right' style={{ borderBottom: '0px' }}>
            <ActionItem icon='edit' onClick={() => props.onSelectDoor && props.onSelectDoor(door)} />
          </TableCell>}
      </TableRow>
      {open &&
        <TableRow key={`collapse${props.index}`}>
          <TableCell style={{ borderBottom: '0px' }} />
          <TableCell style={open ? { paddingBottom: '24px', paddingTop: 0, borderBottom: 0 } : { paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={5}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <>
                {lastProtocol.deviceNumber &&
                  <>
                    <div className={classes.logContainer}>
                      <div className={classes.headline}>{t('doors:deviceNumber')}:</div>
                      <div>{lastProtocol.deviceNumber}</div>
                    </div>
                  </>}
                {lastProtocol.firmware &&
                  <>
                    <div className={classes.logContainer}>
                      <div className={classes.headline}>{t('doors:firmware')}:</div>
                      <div>{lastProtocol.firmware}</div>
                    </div>
                  </>}

                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:firmwareUpdateVersion')}: </div>
                  {lastProtocol.firmwareUpdateVersion &&
                    <CheckIcon />}
                  {!lastProtocol.firmwareUpdateVersion &&
                    <CloseIcon />}
                  <div>{lastProtocol.firmwareUpdateVersion}</div>
                </div>
                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:batteryChangeBatteries')}: </div>
                  {lastProtocol.batteryChangeBatteries &&
                    <CheckIcon />}
                  {!lastProtocol.batteryChangeBatteries &&
                    <CloseIcon />}
                  <div>{lastProtocol.batteryChangeBatteries}</div>
                </div>
                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:ppdUpdateInstallation')}: </div>
                  {lastProtocol.ppdUpdateInstallation &&
                    <CheckIcon />}
                  {!lastProtocol.ppdUpdateInstallation &&
                    <CloseIcon />}
                  <div>{lastProtocol.ppdUpdateInstallation}</div>
                </div>

                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:doorCloserError')}: </div>
                  {!lastProtocol.doorCloserError &&
                    <CheckIcon />}
                  {lastProtocol.doorCloserError &&
                    <CloseIcon />}
                  <div>{lastProtocol.doorCloserError}</div>
                </div>
                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:doorHingesError')}: </div>
                  {!lastProtocol.doorHingesError &&
                    <CheckIcon />}
                  {lastProtocol.doorHingesError &&
                    <CloseIcon />}
                  <div>{lastProtocol.doorHingesError}</div>
                </div>
                <div className={classes.logContainer}>
                  <div className={classes.headline}>{t('doors:morticeLockError')}: </div>
                  {!lastProtocol.morticeLockError &&
                    <CheckIcon />}
                  {lastProtocol.morticeLockError &&
                    <CloseIcon />}
                  <div>{lastProtocol.morticeLockError}</div>
                </div>

                {lastProtocol.generalAbnormalities &&
                  <>
                    <div className={classes.logContainer}>
                      <div className={classes.headline}>{t('doors:generalAbnormalities')}:</div>
                      <div>{lastProtocol.generalAbnormalities}</div>
                    </div>
                  </>}
              </>
            </Collapse>
          </TableCell>
        </TableRow>}
    </>
  )
}
export default TableRowView
