import React, { useCallback } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { IconButton, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import {
  UserDataDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

export interface LoginButtonProps<>{
  user: UserDataDto | undefined
  showSettingsMenu: boolean
  setShowSettingsMenu: (show: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: '14px!important',
      fontWeight: theme.typography.fontWeightRegular,
      padding: '7px',
      color: theme.palette.text.primary,
      borderRadius: '0px',
      borderBottom: '2px solid white',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.text.primary,
      },
    },
  }),
)

const SettingsButton = (props: LoginButtonProps) => {
  const setShowSettingsMenu = props.setShowSettingsMenu
  const showSettingsMenu = props.showSettingsMenu
  const classes = useStyles()

  const onClickUser = useCallback(
    () => {
      setShowSettingsMenu(!showSettingsMenu)
    },
    [setShowSettingsMenu, showSettingsMenu],
  )
  return (
    <div className='setting-container'>
      <IconButton
        onClick={onClickUser}
        className={classes.button}
      >
        {props.user ? props.user.name : ''}
        <ArrowDropDownIcon />
      </IconButton>
    </div>
  )
}

export default SettingsButton
