import { createSelector } from 'reselect'

import { RootState } from '../root.reducer'

export const getUser = createSelector(
  (state: RootState) => state.userStore.user,
  (state) => state,
)
export const getToasts = createSelector(
  (state: RootState) => state.userStore.toasts,
  (state) => state,
)
export const isLogged = createSelector(
  (state: RootState) => state.userStore.isLogged,
  (state) => state,
)
export const getVpsList = createSelector(
  (state: RootState) => state.userStore.vpsList,
  (state) => state,
)
export const getDoor = createSelector(
  (state: RootState) => state.userStore.door,
  (state) => state,
)
export const getRedirectPath = createSelector(
  (state: RootState) => state.userStore.redirectPath,
  (state) => state,
)
export const getDoorList = createSelector(
  (state: RootState) => state.userStore.doorList,
  (state) => state,
)
export const onError = createSelector(
  (state: RootState) => state.userStore.error,
  (state) => state,
)
export const onSuccess = createSelector(
  (state: RootState) => state.userStore.saveSuccess,
  (state) => state,
)
export const onImportSuccessful = createSelector(
  (state: RootState) => state.userStore.importSuccessful,
  (state) => state,
)
