import React from 'react'
import Cookies from 'universal-cookie'

import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Card, Avatar, List, ListItemText, Grid, TextField, Autocomplete } from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

import Env from '~config/envConfig'
import './style.scss'
import colors from '../../constants/colors.json'

import { UserRole } from '~shared/models/Model'
import { UserDataDto, VpsInfoDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

const styles = () => createStyles({
  card: {
    cursor: 'pointer',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60,
    backgroundColor: '#EFEFEF',

  },
  dashboardIcon: {
    fontSize: 24,
  },
})

interface VPSComponentProps extends WithStyles<typeof styles>{
  user: UserDataDto
  vps: VpsInfoDto[]
}

const VPSComponent: React.FC<VPSComponentProps> = (props: VPSComponentProps) => {
  const { classes } = props

  const basePath = Env.restBase
  const cookies = new Cookies()
  const vpsLink = (id: string) => {
    const url = `${basePath}/api/vps/proxy/${id}`
    fetch(url,
      {
        credentials: 'include',
      })
      .then(res => res.json())
      .then(json => {
        cookies.set('vpstoken', json.token, { path: '/' })
        fetch(json.proxyUrl, {
          credentials: 'include',
          headers: {
            vpstoken: cookies.get('vpstoken'),
            cookie: cookies.get('vpstoken'),
          },
        })
          .then(res => {
            if (res.status === 200) {
              window.open(json.proxyUrl, '_blank')
            }
          })
      })
  }

  const vps = props.vps

  return (
    <Grid item xs={12} container spacing={3}>
      {(vps.length > 6)
        ? (
          <Grid item xs={3}>
            <Autocomplete
              id='vpsSelect'
              options={vps}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%' }}
              onChange={(event, option) => { option && vpsLink(option.id) }}
              renderInput={(params) => <TextField {...params} label='Virtual Private Server' variant='outlined' />}
            />
          </Grid>
        ) : (
          vps.map((vps: VpsInfoDto, index: number) => {
            return (
              <Grid item xs={3} key={index}>
                <Card className={classes.card}>
                  <List className={classes.list} onClick={() => vpsLink(vps.id)}>
                    <Avatar
                      alt='Logo'
                      className={`${classes.avatar} dashboard-icon`}
                    >
                      <VpnKeyIcon className={classes.dashboardIcon} style={{ color: colors.darkRed }} />
                    </Avatar>
                    <ListItemText primary={vps.name} secondary='Virtual Private Server' className='dashboard-hover' />
                  </List>
                </Card>
              </Grid>
            )
          })
        )}
    </Grid>
  )
}

export default withStyles(styles)(VPSComponent)
