import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { createStyles, makeStyles } from '@mui/styles'
import { Divider, FormControl, Grid, Paper, TextField, Typography, Button, Dialog, Theme } from '@mui/material'

import { UserDataDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { useDispatch } from 'react-redux'
import { updateUserDataInitiated } from '~store/user/user.actions'
import { UpdateUserDataRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/UserDataApi'
import ChangePassword from '~shared/components/TwoFactorAuthentificationLogin/ChangePassword'
import { appLayout } from '~screens/App/App'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    margin: {
      margin: `${theme.spacing(2)}!important`,
    },
    padding: {
      padding: theme.spacing(2),
    },
    width: {
      width: '100%',
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
)

interface Props {
  user?: UserDataDto
}

const Settings: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dispatchChangeUserData = useCallback((userParams: UpdateUserDataRequest) => dispatch(updateUserDataInitiated(userParams)), [dispatch])

  const [email, setEmail] = React.useState(props.user ? props.user.email : '')
  const [phone, setPhone] = React.useState(props.user ? props.user.phone : '')
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>
            {t('common:settings')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <div className={classes.container}>
              <Typography variant='h6' className={classes.margin}>
                {t('common:editProfile')}
              </Typography>
              <Typography
                variant='h6'
                className={`${classes.link} ${classes.margin}`}
                onClick={() => { setShowModal(!showModal) }}
              >{t('settings:changePassword')}
              </Typography>
            </div>
            <Divider />
            <Grid container className={classes.padding}>
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.margin}>
                  <TextField
                    className={classes.margin}
                    color='primary'
                    value={email}
                    disabled
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value) }}
                    label={t('settings:email')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.margin}>
                  <TextField
                    className={classes.margin}
                    color='primary'
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPhone(event.target.value) }}
                    label={t('settings:phone')}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.padding}>
              <Grid item xs={12}>
                <div className={classes.container}>
                  <Button
                    color='secondary'
                    variant={appLayout.button.type}
                    onClick={() => {
                      history.push('/home')
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    color='primary'
                    variant={appLayout.button.type}
                    onClick={() => {
                      dispatchChangeUserData({ userDataInput: { phone, name: props.user ? props.user.name : '' } })
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={showModal}
      >
        <ChangePassword isOpenCallback={(value: boolean) => setShowModal(value)} />
      </Dialog>
    </>
  )
}
export default Settings
