import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import ClearIcon from '@mui/icons-material/Clear'

import { appLayout } from '~screens/App/App'
import { DoorInfoDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

interface Props {
  name: string
  value?: string
  onChangeValue: (value?: string) => void
}

const TableViewDetailProtocolTextfield: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [value, setValue] = React.useState<string | undefined>(props.value)

  useEffect(() => {
    props.onChangeValue(value)
  }, [value])

  return (
    <>
      <TextField
        id={`id${props.name}`}
        name={`name${props.name}`}
        key={`key${props.name}`}
        InputLabelProps={{ shrink: true }}
        label={t(`doors:${props.name}`)}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
        }}
        margin='normal'
        variant={appLayout?.textfield?.type}
        autoFocus={false}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => {
                setValue('')
              }}
              position='end'
            >
              <IconButton
                aria-label='delete'
              >
                {appLayout?.textfield?.deleteIcon === 'delete' &&
                  <DeleteIcon />}
                {appLayout?.textfield?.deleteIcon === 'clear' &&
                  <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
export default TableViewDetailProtocolTextfield
