import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { createStyles, makeStyles, WithStyles, withStyles } from '@mui/styles'
import { Select, Theme, MenuItem } from '@mui/material'

import SettingsButton from './SettingsButton'
import SettingsMenu from './SettingsMenu'

import {
  UserDataDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

interface IIntranetNavigationBarProps extends WithStyles<typeof styles>{
  doLogout: () => void
  user: UserDataDto | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    language: {
      margin: theme.spacing(1),
      width: '60px',
      padding: '1px',
      '&:before': {
        borderBottom: '0px',
      },
    },
  }),
)

const styles = (theme: Theme) => createStyles({
  avatar: {
    float: 'right',
    cursor: 'pointer',
    marginRight: theme.spacing(),
  },
  button: {
    margin: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
})
const NavigationBar: React.FC<IIntranetNavigationBarProps> = (props: IIntranetNavigationBarProps) => {
  const languageList = [
    {
      value: 'de-DE',
      label: 'DE',
    },
    {
      value: 'en-US',
      label: 'EN',
    },
  ]

  const [showSettingsMenu, setShowSettingsMenu] = useState(false)
  const [language, setLanguage] = React.useState<string>(localStorage.getItem('language') || 'en-US')

  useEffect(
    () => {
      localStorage.setItem('language', language)
      i18n.use(initReactI18next)
        .init({ lng: language })
    },
    [language],
  )

  const classes = useStyles()
  return (
    <div className='toolBar'>
      <div className='language-container'>
        <Select
          className={classes.language}
          id='selectLanguage'
          value={language}
          onChange={(event:ChangeEvent<{}>, value: any) => {
            setLanguage(value.props.value)
          }}
        >
          {languageList.map((option: {value: string, label: string}, i: number) => {
            return (
              <MenuItem key={i.toString()} value={option.value}>{option.label}</MenuItem>
            )
          })}
        </Select>
      </div>
      <SettingsButton
        user={props.user}
        showSettingsMenu={showSettingsMenu}
        setShowSettingsMenu={setShowSettingsMenu}
      />
      <SettingsMenu
        user={props.user}
        showSettingsMenu={showSettingsMenu}
        setShowSettingsMenu={setShowSettingsMenu}
        doLogout={props.doLogout}
      />
    </div>
  )
}

export default withStyles(styles as any)(NavigationBar)
