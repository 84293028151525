import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addToast, loginInitiated, Toast } from '~store/user/user.actions'
import {
  Button,
  DialogTitle,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid, Theme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import { LoginRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { isLogged } from '~store/user/user.selector'
import * as userActions from '~store/user/user.actions'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

export interface QRCode {
  name: string
  secretQrCodeBase64: string
}

export interface InitSecret {
  login: string
  password: string
  deviceName: string
}

interface Props {
  isOpenCallback: (e: boolean) => void
  login: string
  password: string
  qrCode: QRCode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '360px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(2),
    },
    width: {
      width: '320px',
    },
  }),
)

const AddTwoFactorAuthentification: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])
  const dispatchGetLogin = useCallback((loginParams: LoginRequest) => dispatch(loginInitiated(loginParams)), [dispatch])

  const [secretCode, setSecretCode] = useState<string>('')

  const userIsLogged: boolean = useSelector(isLogged)
  const dispatchGetUser = useCallback(() => dispatch(userActions.getUserDataInitiated()), [dispatch])

  useEffect(() => {
    dispatchGetUser()
  }, [userIsLogged],
  )

  const _onSubmitSecret = () => {
    props.isOpenCallback(false)
    if (secretCode) {
      const loginRequest: LoginRequest = {
        login: props.login,
        password: props.password,
        oneTimePassword: secretCode,
      }
      dispatchGetLogin(loginRequest)
    } else {
      const toast: Toast = {
        type: Severity.error,
        text: t('twoFactorAuthentification:activateTwoFactorAuthentificationError'),
      }
      dispatchAddToast(toast)
    }
  }

  const _onCancel = () => {
    history.push('/login')
    props.isOpenCallback(false)
  }

  const _onKeyPress = (event) => {
    if (event.key === 'Enter') {
      _onSubmitSecret()
    }
  }

  return (
    <>
      <DialogTitle>
        {t('twoFactorAuthentification:headline')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('twoFactorAuthentification:activateTwoFactorAuthentification')}
        </DialogContentText>
        <Grid
          container
          className={classes.root}
        >
          <Grid item xs={12}>
            <img className={`${classes.margin} ${classes.width}`} src={props.qrCode.secretQrCodeBase64} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={`${classes.margin} ${classes.width}`}
              color='primary'
              value={secretCode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSecretCode(event.target.value) }}
              label={t('twoFactorAuthentification:activateTwoFactorAuthentificationPin')}
              autoFocus
              onKeyPress={_onKeyPress}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          onClick={_onCancel}
        >
          {t('common:cancel')}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={_onSubmitSecret}
        >
          {t('twoFactorAuthentification:activate2FA')}
        </Button>
      </DialogActions>
    </>
  )
}

export default AddTwoFactorAuthentification
