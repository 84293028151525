import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import { DoorInfoDto, DoorProtocolDto, AddDoorProtocolRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Button,
  Grid, Typography, Theme,
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { appLayout, editLayout } from '~screens/App/App'
import TableViewDetailProtocol from '~shared/components/TableView/TableViewDetailProtocol'
import AddIcon from '@mui/icons-material/Add'

import { useDispatch } from 'react-redux'
import TableViewDetailProtocolTextfield from '~shared/components/TableView/TableViewDetailProtocolTextfield'
import TableViewDetailProtocolEditError from '~shared/components/TableView/TableViewDetailProtocolEditError'
import TableViewDetailProtocolEditSuccess from '~shared/components/TableView/TableViewDetailProtocolEditSuccess'
import { AddDoorProtocolOperationRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/DoorApi'
import { createDoorProtocolInitiated } from '~store/user/user.actions'
import { useHistory } from 'react-router'
import { EntityLayout, Group, Element } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },
    button: {
      margin: `${theme.spacing(2)}px 0px`,
    },
    protocolDetails: {
      marginBottom: theme.spacing(2),
    },
    protocolContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& div': {
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body1.fontFamily,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    protocolEditContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    protocolEditElement: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
      minHeight: '78px',
    },
    protocolTextfield: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    headline: {
      fontWeight: 'bold',
      marginRight: theme.spacing(),
    },
  }),
)

export type GridRange = 1|2|3|4|5|6|7|8|9|10|11|12
export type HelperObject = {
  [key: string]: string | null
}

interface Props {
  door?: DoorInfoDto
  onDeselectDoor?: () => void
}

const TableViewDetail: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const entityEditLayout: EntityLayout = editLayout.entities.find((elem) => elem.entity === 'door')

  const [door, setDoor] = React.useState<DoorInfoDto | undefined>(props.door)
  const [activeProtocol, setActiveProtocol] = React.useState<number | null | 'new'>(null)
  const [newProtocol, setNewProtocol] = React.useState<AddDoorProtocolRequest | null>(null)
  const [showDoorData, setShowDoorData] = React.useState<boolean>(false)

  const sortProtocols = (door: DoorInfoDto) => {
    const sortDoor = { ...door }
    const protocols: DoorProtocolDto[] = [...sortDoor.protocols]
    if (protocols.length > 1) {
      protocols.sort((a, b) => (b.createdAt).getTime() - (a.createdAt).getTime())
      sortDoor.protocols = protocols
    }
    return sortDoor
  }

  useEffect(() => {
    if (props.door) {
      const sortedProtocolsDoor = sortProtocols(props.door)
      setDoor(sortedProtocolsDoor)
    }
  }, [props.door])

  const language: string = localStorage.getItem('language') || 'de'
  const history = useHistory()

  let singlePage = false
  if (history.location.pathname.search('tech/door') !== -1) {
    singlePage = true
  }

  const helperGripObject: HelperObject = {}
  const editTab = 0

  const [editElements, setEditElements] = useState<JSX.Element[]>([])
  const [expandGroup, setExpandGroup] = useState<any>()
  const [completeColumns, setCompleteColumns] = useState<number>(1)
  const [completeLines, setCompleteLines] = useState<number>(1)

  const addProtocol = () => {
    const protocol: AddDoorProtocolRequest = {
      deviceNumber: '',
      firmware: '',
      firmwareUpdateVersion: undefined,
      batteryChangeBatteries: undefined,
      ppdUpdateInstallation: undefined,
      doorCloserError: undefined,
      doorHingesError: undefined,
      morticeLockError: undefined,
      generalAbnormalities: undefined,
    }
    setNewProtocol(protocol)
    setActiveProtocol('new')
  }

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>, newExpanded: boolean) => {
    const obj = { ...expandGroup }
    obj[panel].state = newExpanded
    setExpandGroup(obj)
  }

  useEffect(() => {
    const obj: any = {}
    // completeColumns / Line extrahieren
    let lineCounter = 0
    let columnCounter = 0

    // FormGroups extrahieren >
    entityEditLayout.editTabs && entityEditLayout.editTabs.map((tab, index) => {
      tab.formGroups?.map((group: Group) => {
        if (group.position.vertical.to > lineCounter) {
          lineCounter = group.position.vertical.to
        }
        if (group.position.horizontal.to > columnCounter) {
          columnCounter = group.position.horizontal.to
        }
        obj[group.name] = {
          initialOpen: group.initialOpen,
          closable: group.closable,
          state: group.initialOpen,
          editTabIndex: index,
        }
      })
    })

    entityEditLayout.elements.map((element: Element) => {
      if ((element.editTab && entityEditLayout.editTabs && entityEditLayout.editTabs[editTab].name === element.editTab) || !element.editTab) {
        if (element.position.vertical.to > lineCounter) {
          lineCounter = element.position.vertical.to
        }
        if (element.position.horizontal.to > columnCounter) {
          columnCounter = element.position.horizontal.to
        }
      }
    })

    setExpandGroup(obj)
    // +1, weil die Lines / Columns bei 0 anfangen
    setCompleteColumns(columnCounter + 1)
    setCompleteLines(lineCounter + 1)
  }, [])

  const subElements = (group: Group) => {
    const elems: JSX.Element[] = []
    let existingElem: any = null
    const helperSubGridObject: HelperObject = {}
    const groupColumns = group.position.horizontal.to - group.position.horizontal.from + 1
    for (let l = group.position.vertical.from; l <= group.position.vertical.to; l += 1) {
      for (let c = group.position.horizontal.from; c <= group.position.horizontal.to; c += 1) {
        if (!helperSubGridObject[`${l}-${c}`]) {
          existingElem = null
          entityEditLayout.elements.map((groupElement: Element) => {
            if ((groupElement.editTab && entityEditLayout.editTabs && entityEditLayout.editTabs[editTab].name === groupElement.editTab) || !groupElement.editTab) {
              if (c === groupElement.position.horizontal.from && l === groupElement.position.vertical.from) {
                existingElem = groupElement
              }
            }
          })

          if (existingElem) {
            for (let existL = existingElem.position.vertical.from; existL < existingElem.position.vertical.to + 1; existL += 1) {
              for (let existC = existingElem.position.horizontal.from; existC < existingElem.position.horizontal.to + 1; existC += 1) {
                helperSubGridObject[`${existL}-${existC}`] = existingElem.name
              }
            }

            const colWidth: GridRange = existingElem &&
              Math.round(12 / groupColumns * (existingElem.position.horizontal.to - existingElem.position.horizontal.from + 1)) as GridRange

            elems.push(
              <Grid item xs={colWidth} key={`group${group.name}line${l}-column${c}`}>
                <Typography><b>{existingElem.name}:</b></Typography>
                <Typography>{door && door[existingElem.name] && `${door[existingElem.name]}`}</Typography>
              </Grid>,
            )
            c += (existingElem.position.horizontal.to - existingElem.position.horizontal.from)
          } else {
            const colWidth: GridRange = Math.round(12 / groupColumns) as GridRange
            elems.push(
              <Grid item xs={colWidth} key={`group${group.name}line${l}-column${c}`} />,
            )
          }
        }
      }
    }
    return elems
  }

  const addElements = (existingGroup: Group, l: number, c: number) => {
    const columnElements: JSX.Element[] = []
    const colWidth: GridRange = existingGroup && Math.round(12 / completeColumns * (existingGroup.position.horizontal.to - existingGroup.position.horizontal.from + 1)) as GridRange

    const formGroups = entityEditLayout.editTabs && entityEditLayout.editTabs[editTab].formGroups
    let subJoinTable = false
    for (let l = existingGroup.position.vertical.from; l <= existingGroup.position.vertical.to; l += 1) {
      for (let c = existingGroup.position.horizontal.from; c <= existingGroup.position.horizontal.to; c += 1) {
        entityEditLayout.elements.map((groupElement: Element) => {
          if ((groupElement.editTab && entityEditLayout.editTabs && entityEditLayout.editTabs[editTab].name === groupElement.editTab) || !groupElement.editTab) {
            if (c === groupElement.position.horizontal.from && l === groupElement.position.vertical.from) {
              subJoinTable = groupElement.name === 'crudeIncomingReference'
            }
          }
        })
      }
    }
    if (!subJoinTable) {
      if (formGroups && formGroups.length > 1 &&
        expandGroup && expandGroup[existingGroup.name] &&
        expandGroup[existingGroup.name].editTabIndex === editTab) {
        columnElements.push(
          <Grid item xs={colWidth} key={`group${existingGroup.name}line${l}-column${c}`}>
            <Accordion
              id={existingGroup.name}
              expanded={expandGroup && expandGroup[existingGroup.name] && expandGroup[existingGroup.name].closable ? expandGroup[existingGroup.name].state : true}
              onChange={handleChange(existingGroup.name)}
            >
              <AccordionSummary
                id={`AccSumline${l}-column${c}`}
                expandIcon={expandGroup && expandGroup[existingGroup.name] && expandGroup[existingGroup.name].closable &&
                  <ExpandMoreIcon />}
              >
                {language && existingGroup.title &&
                  <Typography>{existingGroup.title[language]}</Typography>}
              </AccordionSummary>
              <AccordionDetails id={`AccDetline${l}-column${c}`}>
                <Grid
                  container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  key={`GridConline${l}-column${c}`}
                >
                  {language && existingGroup.description &&
                    <Grid item xs={12}>
                      <Typography
                        variant='body2'
                      >{existingGroup.description[language]}
                      </Typography>
                    </Grid>}
                  {subElements(existingGroup)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>,
        )
      } else {
        columnElements.push(
          <Grid item xs={colWidth} key={`group${existingGroup.name}line${l}-column${c}`}>
            {language && existingGroup.title &&
              <Typography>{existingGroup.title[language]}</Typography>}
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={`GridConline${l}-column${c}`}>
              {language && existingGroup.description &&
                <Grid item xs={12}>
                  <Typography
                    variant='body2'
                  >{existingGroup.description[language]}
                  </Typography>
                </Grid>}
              {subElements(existingGroup)}
            </Grid>
          </Grid>,
        )
      }
    }
    return columnElements
  }

  useEffect(() => {
    const columnElements: JSX.Element[] = []
    let existingGroup: any = null
    for (let l = 0; l < completeLines; l += 1) {
      for (let c = 0; c < completeColumns; c += 1) {
        if (!helperGripObject[`${l}-${c}`]) {
          existingGroup = null

          entityEditLayout.editTabs && entityEditLayout.editTabs[editTab].formGroups?.map((group: any) => {
            if (c === group.position.horizontal.from && l === group.position.vertical.from) {
              existingGroup = group
            }
          })
          if (existingGroup) {
            for (let existL = existingGroup.position.vertical.from; existL < existingGroup.position.vertical.to + 1; existL += 1) {
              for (let existC = existingGroup.position.horizontal.from; existC < existingGroup.position.horizontal.to + 1; existC += 1) {
                helperGripObject[`${existL}-${existC}`] = existingGroup.name
              }
            }
            columnElements.push(<>{addElements(existingGroup, l, c)}</>)
          } else {
            const colWidth: GridRange = Math.round(12 / completeColumns) as GridRange
            helperGripObject[`${l}-${c}`] = ''
            // TODO hier noch prüfen ob ggf. Elemente angelegt worden sind, die in keiner formGroup / Tab sind
            // die müssen hier rein und werden per default dem ersten Tab zugeordet
            columnElements.push(
              <Grid item xs={colWidth} key={`line${l}-column${c}`} />,
            )
          }
        }
      }
    }
    setEditElements(columnElements)
  }, [expandGroup, language])

  const dispatch = useDispatch()
  const dispatchAddNewProtocol = useCallback((addProtocol: AddDoorProtocolOperationRequest) => dispatch(createDoorProtocolInitiated(addProtocol)), [dispatch])

  const saveNewProtocol = () => {
    if (props.door && newProtocol) {
      const addProtocol: AddDoorProtocolOperationRequest = {
        addDoorProtocolRequest: newProtocol,
        doorId: props.door.doorId,
      }
      dispatchAddNewProtocol(addProtocol)
      setActiveProtocol(null)
      setNewProtocol(null)
    }
  }

  const deleteNewProtocol = () => {
    setNewProtocol(null)
    setActiveProtocol(null)
  }

  const showData = () => {
    setShowDoorData(true)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* Protokolle */}
        {!showDoorData &&
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <Button className={classes.button} size='small' onClick={addProtocol} color='primary'>
                <AddIcon />{t('doors:createNewProtocolEntry')}
              </Button>
              <Button className={classes.button} size='small' onClick={showData} color='primary'>
                {t('doors:showDoorData')}
              </Button>
            </div>
            {newProtocol &&
              <>
                <Accordion
                  className={classes.protocolDetails}
                  id='accNew'
                  expanded={activeProtocol === 'new'}
                  onChange={() => {
                    activeProtocol === 'new' ? setActiveProtocol(null) : setActiveProtocol('new')
                  }}
                >
                  <AccordionSummary
                    id='accSumNew'
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography> {t('doors:newProtocolEntry')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    id='accDetNew'
                  >
                    <div className={classes.protocolEditContainer}>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolTextfield
                          name='deviceNumber' value={newProtocol.deviceNumber} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              deviceNumber: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolTextfield
                          name='firmware' value={newProtocol.firmware} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              firmware: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditSuccess
                          name='firmwareUpdateVersion' value={newProtocol.firmwareUpdateVersion} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              firmwareUpdateVersion: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditSuccess
                          name='batteryChangeBatteries' value={newProtocol.batteryChangeBatteries} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              batteryChangeBatteries: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditSuccess
                          name='ppdUpdateInstallation' value={newProtocol.ppdUpdateInstallation} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              ppdUpdateInstallation: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditError
                          name='doorCloserError' value={newProtocol.doorCloserError} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              doorCloserError: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditError
                          name='doorHingesError' value={newProtocol.doorHingesError} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              doorHingesError: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolEditError
                          name='morticeLockError' value={newProtocol.morticeLockError} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              morticeLockError: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.protocolEditElement}>
                        <TableViewDetailProtocolTextfield
                          name='generalAbnormalities' value={newProtocol.generalAbnormalities} onChangeValue={(value?: string) => {
                            const changeProtocol = {
                              ...newProtocol,
                              generalAbnormalities: value,
                            }
                            setNewProtocol(changeProtocol)
                          }}
                        />
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          color='secondary'
                          variant='outlined'
                          onClick={() => {
                            deleteNewProtocol()
                          }}
                        >
                          {t('common:cancel')}
                        </Button>
                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={() => {
                            saveNewProtocol()
                          }}
                        >
                          {t('common:save')}
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>}
            {door && door.protocols.map((protocol:DoorProtocolDto, index: number) => {
              return (
                <TableViewDetailProtocol
                  key={index}
                  doorProtocol={protocol}
                  index={index}
                  open={activeProtocol === index}
                  changeActiveProtocol={(index: number | null) => setActiveProtocol(index)}
                />
              )
            })}
          </Grid>}
        {/* Türdaten */}
        {showDoorData &&
          <Grid item xs={12}>
            <Grid item xs={12} key='generalData'>
              <Grid container spacing={2}>
                {editElements.map((elem) => {
                  return elem
                })}
              </Grid>
              <Button className={classes.button} size='small' onClick={() => setShowDoorData(false)} color='primary'>
                {t('common:back')}
              </Button>
            </Grid>
          </Grid>}
      </Grid>
      {!singlePage &&
        <Button className={classes.button} size='small' onClick={() => props.onDeselectDoor && props.onDeselectDoor()} color='primary'>
          {t('common:back')}
        </Button>}
    </div>
  )
}
export default TableViewDetail
