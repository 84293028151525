import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router-dom'

import { SnackbarProvider } from 'notistack'

import { CssBaseline, ThemeProvider } from '@mui/material'

import { CrudeContext, useCrude } from '@3m5/crude-frontend/dist/crudeHooks'
import TableActionButtons from '~shared/components/TableActionButtons/TableActionButtons'

import Dashboard from './screens/Dashboard'
import Door from './screens/Door'
import Settings from './screens/Settings'
import Login from '~shared/components/TwoFactorAuthentificationLogin/Login'
import ResetPassword from '~shared/components/TwoFactorAuthentificationLogin/ResetPassword'

import NavigationContainer from '~shared/components/Navigation/NavigationContainer'
import { AppSetting, UserRole } from '~shared/models/Model'

import Env from '../../config/envConfig'
import { logoutInitiated, setRedirectPath } from '~store/user/user.actions'
import { getUser, getRedirectPath } from '~store/user/user.selector'

import './App.scss'
import TechDoor from '~screens/App/screens/TechDoor'
import { getAppTheme } from '~config/getAppTheme'
import SnackbarComponent from '~shared/components/MessageComponents/SnackbarComponent'
import { EditLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

export const editLayout: EditLayout = {
  version: 1,
  entities: [
    {
      entity: 'door',
      elements: [
        {
          name: 'doorId',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 0,
              to: 0,
            },
          },
        },
        {
          name: 'shortId',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 0,
              to: 0,
            },
          },
        },
        {
          name: 'name',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          name: 'customer',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          name: 'city',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 2,
              to: 2,
            },
          },
        },
        {
          name: 'building',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 2,
              to: 2,
            },
          },
        },
        {
          name: 'buildingPart',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 3,
              to: 3,
            },
          },
        },
        {
          name: 'floor',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 3,
              to: 3,
            },
          },
        },
        {
          name: 'notes',
          position: {
            horizontal: {
              from: 0,
              to: 1,
            },
            vertical: {
              from: 4,
              to: 4,
            },
          },
        },
        {
          name: 'doorFitting1',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 5,
              to: 5,
            },
          },
        },
        {
          name: 'doorFitting2',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 5,
              to: 5,
            },
          },
        },
        {
          name: 'doorFitting3',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 6,
              to: 6,
            },
          },
        },
        {
          name: 'doorFitting4',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 6,
              to: 6,
            },
          },
        },
        {
          name: 'doorLockCylinder1',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 7,
              to: 7,
            },
          },
        },
        {
          name: 'doorLockCylinder2',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 7,
              to: 7,
            },
          },
        },
        {
          name: 'doorLockCylinder3',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 8,
              to: 8,
            },
          },
        },
        {
          name: 'doorLockCylinder4',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 8,
              to: 8,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint1',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 9,
              to: 9,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint2',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 9,
              to: 9,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint3',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 10,
              to: 10,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint4',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 10,
              to: 10,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint5',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 11,
              to: 11,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint6',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 11,
              to: 11,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint7',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 12,
              to: 12,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint8',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 12,
              to: 12,
            },
          },
        },
        {
          name: 'doorWiredAccessPoint9',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 13,
              to: 13,
            },
          },
        },
        {
          name: 'lockProducer',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 14,
              to: 14,
            },
          },
        },
        {
          name: 'lockArticleNumber',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 14,
              to: 14,
            },
          },
        },
        {
          name: 'lockBackset',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 15,
              to: 15,
            },
          },
        },
        {
          name: 'lockFaceplateWidth',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 15,
              to: 15,
            },
          },
        },
        {
          name: 'lockDistance',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 16,
              to: 16,
            },
          },
        },
        {
          name: 'lockFaceplate',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 16,
              to: 16,
            },
          },
        },
        {
          name: 'lockDoorVariant',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 17,
              to: 17,
            },
          },
        },
        {
          name: 'lockPanicFunction',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 17,
              to: 17,
            },
          },
        },
        {
          name: 'lockVariant',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 18,
              to: 18,
            },
          },
        },
        {
          name: 'closerProducer',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 19,
              to: 19,
            },
          },
        },
        {
          name: 'closerArticleNumber',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 19,
              to: 19,
            },
          },
        },
        {
          name: 'leafMaterial',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 20,
              to: 20,
            },
          },
        },
        {
          name: 'leafStop',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 20,
              to: 20,
            },
          },
        },
        {
          name: 'leafOpeningDirection',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 21,
              to: 21,
            },
          },
        },
        {
          name: 'leafThickness',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 21,
              to: 21,
            },
          },
        },
        {
          name: 'leafFireProtectionClass',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 22,
              to: 22,
            },
          },
        },
        {
          name: 'leafNotes',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 22,
              to: 22,
            },
          },
        },
        {
          name: 'otherAlerting',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 23,
              to: 23,
            },
          },
        },
        {
          name: 'otherEscape',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 23,
              to: 23,
            },
          },
        },
        {
          name: 'otherPanicDoor',
          position: {
            horizontal: {
              from: 0,
              to: 0,
            },
            vertical: {
              from: 24,
              to: 24,
            },
          },
        },
        {
          name: 'otherDoorOpener',
          position: {
            horizontal: {
              from: 1,
              to: 1,
            },
            vertical: {
              from: 24,
              to: 24,
            },
          },
        },
      ],
      editTabs: [
        {
          name: 'details',
          formGroups: [
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 0,
                  to: 4,
                },
              },
              title: {
                'de-DE': 'Allgemeine Daten des Zutrittspunktes',
                'en-US': 'General data',
              },
              name: 'generalData',
              initialOpen: true,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 5,
                  to: 6,
                },
              },
              title: {
                'de-DE': 'Türbeschlag',
                'en-US': 'Door fitting',
              },
              name: 'doorFitting',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 7,
                  to: 8,
                },
              },
              title: {
                'de-DE': 'Schließzylinder',
                'en-US': 'Lock cylinder',
              },
              name: 'lockCylinder',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 9,
                  to: 13,
                },
              },
              title: {
                'de-DE': 'Verkabelter Zutrittspunkt',
                'en-US': 'Wired access point',
              },
              name: 'wiredAccessPoint',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 14,
                  to: 18,
                },
              },
              title: {
                'de-DE': 'Schloss',
                'en-US': 'Door lock',
              },
              name: 'doorLock',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 19,
                  to: 19,
                },
              },
              title: {
                'de-DE': 'Türschliesser',
                'en-US': 'Door closer',
              },
              name: 'doorCloser',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 20,
                  to: 22,
                },
              },
              title: {
                'de-DE': 'Türblatt',
                'en-US': 'Door leaf',
              },
              name: 'doorLeaf',
              initialOpen: false,
              closable: true,
            },
            {
              position: {
                horizontal: {
                  from: 0,
                  to: 1,
                },
                vertical: {
                  from: 23,
                  to: 24,
                },
              },
              title: {
                'de-DE': 'Sonstige Anbauten',
                'en-US': 'Other',
              },
              name: 'other',
              initialOpen: false,
              closable: true,
            },
          ],
        }],
    },
  ],
}
export const appLayout = {
  version: 1,
  maxTreeViewLevel: 3,
  button: {
    type: 'outlined',
  },
  textfield: {
    type: 'outlined',
    deleteIcon: 'delete',
  },
  format: {
    date: {
      de: 'dd.MM.yyyy',
      en: 'dd/MM/yyyy',
    },
    time: {
      de: 'HH:mm',
      en: 'HH:mm',
    },
  },
  elements: [],
}
export const theme = getAppTheme()

const App: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  // editLayout anpassen fürs Crude
  const crudeEditLayout = { ...editLayout }
  crudeEditLayout.entities.forEach(entity => {
    if (entity.entity === 'door') {
      entity.elements.forEach(element => {
        if (element.name === 'doorId') {
          element.name = 'id'
        }
        if (element.name === 'customer') {
          element.name = 'customerId'
        }
        return element
      })
    }
    return entity
  })

  const user = useSelector(getUser)
  const redirectPath = useSelector(getRedirectPath)

  const [token, setToken] = useState<string>('')
  const [shortDoorId, setShortDoorId] = useState<string | null>(null)

  useEffect(() => {
    user && localStorage.setItem('userRole', user.role)
    setShortDoorId(null)
    let pathname = 'home'
    let tokenElem = ''

    if (!user) {
      pathname = 'login'
    } else {
      if (redirectPath) {
        history.push(redirectPath)
      }
    }

    if (history.location.pathname.search('tech/door') !== -1) {
      if (user) {
        dispatch(setRedirectPath(undefined))
        pathname = 'techDoor'
      } else {
        dispatch(setRedirectPath(history.location.pathname))
      }
    }
    if (history.location.pathname.search('setpassword') !== -1) {
      pathname = 'setpassword'
    }
    if (history.location.pathname.search('resetpassword') !== -1) {
      pathname = 'resetpassword'
    }
    switch (pathname) {
      case 'login':
        history.push('/login')
        break
      case 'techDoor':
        setShortDoorId(history.location.pathname.replace('tech/door/', '').replace('/', ''))
        break
      case 'setpassword':
        tokenElem = history.location.pathname.replace('setpassword/', '').replace('/', '')
        setToken(tokenElem)
        if (user) {
          dispatchGetLogout()
        }
        break
      case 'resetpassword':
        tokenElem = history.location.pathname.replace('resetpassword/', '').replace('/', '')
        setToken(tokenElem)
        if (user) {
          dispatchGetLogout()
        }
        break
      default:
        history.push('/home')
        break
    }
  }, [history, user],
  )

  const basePath = Env.crudeRestBase

  const settings: AppSetting[] = [
    {
      title: t('navigation:start'),
      path: '/home',
      icon: 'home',
      desc: '',
      roles: [UserRole.User, UserRole.CustomerAdmin, UserRole.PortalAdmin],
    },
    {
      title: t('navigation:customer'),
      path: '/apps/crm/customer',
      icon: 'descriptions',
      desc: t('descriptions:customer'),
      line: 1,
      roles: [UserRole.PortalAdmin],
    },
    {
      title: t('navigation:user'),
      path: '/apps/crm/user',
      icon: 'people',
      desc: t('descriptions:user'),
      line: 1,
      roles: [UserRole.CustomerAdmin, UserRole.PortalAdmin],
    },
    {
      title: t('navigation:vps'),
      path: '/apps/crm/vps',
      icon: 'list',
      desc: t('descriptions:vps'),
      line: 1,
      roles: [UserRole.PortalAdmin],
    },
    {
      title: t('navigation:door'),
      path: '/apps/crm/door',
      icon: 'lock',
      desc: t('descriptions:door'),
      line: 1,
      roles: [UserRole.PortalAdmin],
    },
    {
      title: t('navigation:doorProtocol'),
      path: '/apps/crm/doorProtocol',
      icon: 'subject',
      desc: t('descriptions:doorProtocol'),
      line: 1,
      roles: [UserRole.PortalAdmin],
    },
    {
      title: t('navigation:door'),
      path: '/apps/doors',
      icon: 'lock',
      desc: t('descriptions:door'),
      line: 2,
      roles: [UserRole.User, UserRole.TechUser, UserRole.CustomerAdmin],
    },
    {
      title: t('navigation:settings'),
      path: '/apps/settings',
      icon: 'settings',
      desc: t('descriptions:settings'),
      line: 3,
      roles: [UserRole.User, UserRole.CustomerAdmin, UserRole.PortalAdmin, UserRole.TechUser],
    },
  ]

  const configuration: any = {
    theme,
    path: '/apps/crm',
    api: {
      app: 'crm',
      restPath: `${basePath}`,
    },
    appLayout,
    editLayout: crudeEditLayout,
  }

  const dispatch = useDispatch()
  const dispatchGetLogout = useCallback(() => dispatch(logoutInitiated()), [dispatch])

  const DashboardApp = () => (<Dashboard settings={settings} user={user} />)
  const SettingsApp = () => (<Settings user={user} />)
  const DoorApp = () => (<Door user={user} />)

  const ResetPasswordApp = () => (<ResetPassword token={token} />)

  const crudeContext = useCrude({ appPageProps: configuration, tableActionComponent: TableActionButtons })

  return (
    <>
      <CrudeContext.Provider value={crudeContext}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            {!shortDoorId &&
              <>
                <NavigationContainer
                  items={settings}
                  user={user}
                  hideNav={false}
                  doLogout={() => {
                    dispatchGetLogout()
                  }}
                />
                <div className='main'>
                  <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route path='/resetpassword' component={ResetPasswordApp} />
                    <Route path='/setpassword' component={ResetPasswordApp} />
                    <Route exact path='/home' component={DashboardApp} />
                    <Route exact path='/apps/crm/user' component={crudeContext.Crude} />
                    <Route exact path='/apps/crm/customer' component={crudeContext.Crude} />
                    <Route exact path='/apps/crm/vps' component={crudeContext.Crude} />
                    <Route exact path='/apps/crm/door' component={crudeContext.Crude} />
                    <Route exact path='/apps/crm/doorProtocol' component={crudeContext.Crude} />
                    <Route exact path='/apps/doors' component={DoorApp} />
                    <Route exact path='/apps/settings' component={SettingsApp} />
                  </Switch>
                </div>
                {TableActionButtons}
              </>}
            {shortDoorId && <TechDoor user={user} shortDoorId={shortDoorId} />}
            <SnackbarComponent />
          </SnackbarProvider>
        </ThemeProvider>
      </CrudeContext.Provider>
    </>
  )
}

export default App
