import React from 'react'
import { Button } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import LoupeIcon from '@mui/icons-material/Loupe'
import DeleteIcon from '@mui/icons-material/Delete'

interface IActionItemData {
  icon: string
  tooltip?: string
  onClick?: () => void
}

const ActionItem: React.FC<IActionItemData> = (props) => {
  return (
    <Button size='small' onClick={() => props.onClick && props.onClick()}>
      {props.icon === 'edit' ? <EditIcon /> : props.icon === 'loupe' ? <LoupeIcon /> : <DeleteIcon />}
    </Button>
  )
}
export default ActionItem
