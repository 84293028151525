import { all } from 'redux-saga/effects'
import { userSagas } from './user/user.sagas'

export function * rootSaga () {
  // add saga entrypoints here
  // the entrypoints should then use fork to add all necessary watchers
  yield all([
    userSagas(),
  ])
}
