import { Theme } from '@mui/material'

export interface AppSetting {
  title: string
  path: string
  icon: string
  img?: string
  desc?: string
  line?: number
  roles?: UserRole[]
}

export interface VpnSettings {
  id: string
  name: string
}

export enum UserRole {
  User = 'User',
  CustomerAdmin = 'CustomerAdmin',
  PortalAdmin = 'PortalAdmin',
  TechUser = 'TechUser',
}

export interface CRMConfiguration {
  theme?: Theme
  path: string
  restUrl: string
  entities: string[]
}
