import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@mui/styles'

import { DoorProtocolDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import {
  Accordion, Theme,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import { appLayout } from '~screens/App/App'
import { format } from 'date-fns'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    protocolDetails: {
      marginBottom: theme.spacing(2),
    },
    protocolContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& div': {
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body1.fontFamily,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
      },
    },
    protocolTextfield: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    headline: {
      fontWeight: 'bold',
      marginRight: theme.spacing(),
    },
  }),
)

interface Props {
  doorProtocol: DoorProtocolDto
  index: number
  open: boolean
  changeActiveProtocol: (index: number | null) => void
}

const TableViewDetailProtocol: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const offsetInSeconds = new Date().getTimezoneOffset() * 60

  const index = props.index
  const [doorProtocol, setDoorProtocol] = React.useState<DoorProtocolDto>(props.doorProtocol)
  const [expand, setExpand] = useState<boolean>(props.open)

  useEffect(() => {
    setDoorProtocol(props.doorProtocol)
  }, [props.doorProtocol])

  useEffect(() => {
    setExpand(props.open)
  }, [props.open])

  enum Format {
    date = 'date',
    dateTime = 'dateTime',
    time = 'time',
  }

  const getDefaultFormat = (format: Format) => {
    switch (format) {
      case Format.date:
        return 'dd.MM.yyyy'
      case Format.time:
        return 'HH:mm'
      case Format.dateTime:
        return 'dd.MM.yyyy HH:mm'
    }
  }

  const formatDate = (date: number, dateForm?: Format) => {
    const dateFormat = dateForm ? getDefaultFormat(dateForm) : getDefaultFormat(Format.date)
    return date ? format(new Date(date * 1000), dateFormat) : ''
  }

  return (
    <>
      <Accordion
        className={classes.protocolDetails}
        id={`acc${index}`}
        expanded={expand}
        onChange={() => {
          !expand ? props.changeActiveProtocol(props.index) : props.changeActiveProtocol(null)
        }}
      >
        <AccordionSummary
          id={`accSum${index}`}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography> {`${formatDate((new Date(doorProtocol.createdAt).getTime() / 1000 - offsetInSeconds), Format.dateTime)} (${doorProtocol.user})`}</Typography>
        </AccordionSummary>
        <AccordionDetails
          id={`accDet${index}`}
        >
          <div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:deviceNumber')}:</div>
              <div>{props.doorProtocol.deviceNumber}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:firmware')}:</div>
              <div>{props.doorProtocol.firmware}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:firmwareUpdateVersion')}: </div>
              {props.doorProtocol.firmwareUpdateVersion &&
                <CheckIcon />}
              {!props.doorProtocol.firmwareUpdateVersion &&
                <CloseIcon />}
              <div>{props.doorProtocol.firmwareUpdateVersion}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:batteryChangeBatteries')}: </div>
              {props.doorProtocol.batteryChangeBatteries &&
                <CheckIcon />}
              {!props.doorProtocol.batteryChangeBatteries &&
                <CloseIcon />}
              <div>{props.doorProtocol.batteryChangeBatteries}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:ppdUpdateInstallation')}: </div>
              {props.doorProtocol.ppdUpdateInstallation &&
                <CheckIcon />}
              {!props.doorProtocol.ppdUpdateInstallation &&
                <CloseIcon />}
              <div>{props.doorProtocol.ppdUpdateInstallation}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:doorCloserError')}: </div>
              {!props.doorProtocol.doorCloserError &&
                <CheckIcon />}
              {props.doorProtocol.doorCloserError &&
                <CloseIcon />}
              <div>{props.doorProtocol.doorCloserError}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:doorHingesError')}: </div>
              {!props.doorProtocol.doorHingesError &&
                <CheckIcon />}
              {props.doorProtocol.doorHingesError &&
                <CloseIcon />}
              <div>{props.doorProtocol.doorHingesError}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:morticeLockError')}: </div>
              {!props.doorProtocol.morticeLockError &&
                <CheckIcon />}
              {props.doorProtocol.morticeLockError &&
                <CloseIcon />}
              <div>{props.doorProtocol.morticeLockError}</div>
            </div>
            <div className={classes.protocolContainer}>
              <div className={classes.headline}>{t('doors:generalAbnormalities')}:</div>
              <div>{props.doorProtocol.generalAbnormalities}</div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
export default TableViewDetailProtocol
