import { reducerWithInitialState } from 'typescript-fsa-reducers'
import produce from 'immer'

import {
  UserDataDto,
  VpsInfoDto,
  DoorInfoDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import {
  login,
  logout,
  forgotPassword,
  updatePassword,
  resetPassword,
  getUserData,
  updateUserData,
  getDoorData,
  createDoorProtocol,
  getVpsList,
  setRedirectPath,
  addToast, Toast,
  resetUserSecret, getDoorList, exportDoorListAdmin, exportDoorListTech, importDoorListTech, importDoorListAdmin,
  printDoorQRAdmin,
} from './user.actions'

import { ApiError } from '~store/root.reducer'

export interface UserState {
  fetching: boolean
  user?: UserDataDto
  isLogged: boolean
  vpsList?: VpsInfoDto[]
  doorList?: DoorInfoDto[]
  door?: DoorInfoDto
  error: ApiError
  saveSuccess?: string
  toasts?: Toast
  importSuccessful: boolean
  redirectPath?: string
}

const INITIAL_STATE: UserState = {
  fetching: false,
  importSuccessful: false,
  isLogged: false,
  error: {},

}

export const userReducer = reducerWithInitialState(INITIAL_STATE)
  /**
   * login
   */
  .case(login.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.login
    })
  })
  .case(login.done, (state, { result }) => {
    return produce(state, draft => {
      draft.isLogged = true
      draft.fetching = false
    })
  })
  .case(login.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.login = errorStatus
    })
  })
  /**
   * logout
   */
  .case(logout.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.logout
    })
  })
  .case(logout.done, (state, { result }) => {
    return produce(state, draft => {
      draft.user = undefined
      draft.isLogged = false
      draft.fetching = false
      window.location.reload()
    })
  })
  .case(logout.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.logout = (error as Response)
    })
  })
  .case(forgotPassword.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.forgotPassword
    })
  })
  .case(forgotPassword.done, (state, { result }) => {
    return produce(state, draft => {
      draft.fetching = false
    })
  })
  .case(forgotPassword.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.forgotPassword = (error as Response)
    })
  })
  .case(resetPassword.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.resetPassword
    })
  })
  .case(resetPassword.done, (state, { result }) => {
    return produce(state, draft => {
      draft.fetching = false
    })
  })
  .case(resetPassword.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.resetPassword = (error as Response)
    })
  })
  .case(updatePassword.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.updatePassword
    })
  })
  .case(updatePassword.done, (state, { params, result }) => {
    return produce(state, draft => {
      draft.fetching = false
    })
  })

  .case(updatePassword.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.updatePassword = (error as Response)
    })
  })

  .case(getUserData.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.getUserData
    })
  })

  .case(getUserData.done, (state, { result }) => {
    return produce(state, draft => {
      draft.user = result
      draft.fetching = false
    })
  })

  .case(getUserData.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.getUserData = (error as Response)
    })
  })
  .case(updateUserData.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.updateUserData
    })
  })
  .case(updateUserData.done, (state, { params, result }) => {
    return produce(state, draft => {
      draft.user = result
      draft.fetching = false
    })
  })

  .case(updateUserData.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.updateUserData = (error as Response)
    })
  })

  .case(getDoorData.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.getDoorData
    })
  })

  .case(getDoorData.done, (state, { result }) => {
    return produce(state, draft => {
      draft.door = result
      draft.fetching = false
    })
  })

  .case(getDoorData.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.getDoorData = (error as Response)
    })
  })

  .case(getDoorList.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.getDoorList
    })
  })

  .case(getDoorList.done, (state, { result }) => {
    return produce(state, draft => {
      draft.doorList = result
      draft.fetching = false
    })
  })

  .case(getDoorList.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.getDoorList = (error as Response)
    })
  })
  .case(createDoorProtocol.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      draft.saveSuccess = undefined
      delete draft.error.createDoorProtocol
    })
  })
  .case(createDoorProtocol.done, (state, { params, result }) => {
    return produce(state, draft => {
      draft.doorList = result
      draft.saveSuccess = 'createDoorProtocol'
      draft.fetching = false
    })
  })

  .case(createDoorProtocol.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.createDoorProtocol = (error as Response)
    })
  })

  .case(getVpsList.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.getVpsList
    })
  })

  .case(getVpsList.done, (state, { result }) => {
    return produce(state, draft => {
      const sortedResult = result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      draft.vpsList = sortedResult
      draft.fetching = false
    })
  })

  .case(getVpsList.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.getVpsList = errorStatus
    })
  })
  /**
   * resetSecrets
   */
  .case(resetUserSecret.started, state => {
    return produce(state, draft => {
      draft.fetching = true
    })
  })
  .case(resetUserSecret.done, (state, { params }) => {
    return produce(state, draft => {
      draft.fetching = false
    })
  })
  .case(resetUserSecret.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      draft.error.resetUserSecret = (error as Response)
    })
  })
  /**
   * Toast Management
   */
  .case(addToast, (state, payload) => {
    return produce(state, (draft) => {
      draft.toasts = payload
    })
  })

  /**
   * Print QR
   */
  .case(printDoorQRAdmin.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.printDoorQRAdmin
    })
  })

  .case(printDoorQRAdmin.done, (state, { result }) => {
    return produce(state, draft => {
      const url = window.URL.createObjectURL(result)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'printDoors.pdf'
      document.body.appendChild(a)
      a.click()
      a.remove()
      draft.fetching = false
    })
  })

  .case(printDoorQRAdmin.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.printDoorQRAdmin = (error as Response)
    })
  })

  /**
   * Import / Export
   */
  .case(exportDoorListAdmin.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.exportDoorListAdmin
    })
  })

  .case(exportDoorListAdmin.done, (state, { result }) => {
    return produce(state, draft => {
      const url = window.URL.createObjectURL(result)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'exportDoors.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
      draft.fetching = false
    })
  })

  .case(exportDoorListAdmin.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.exportDoorListAdmin = (error as Response)
    })
  })
  .case(exportDoorListTech.started, state => {
    return produce(state, draft => {
      draft.fetching = true
      delete draft.error.exportDoorListTech
    })
  })

  .case(exportDoorListTech.done, (state, { result }) => {
    return produce(state, draft => {
      const url = window.URL.createObjectURL(result)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'exportDoors.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
      draft.fetching = false
    })
  })

  .case(exportDoorListTech.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.exportDoorListTech = (error as Response)
    })
  })
  .case(importDoorListTech.started, state => {
    return produce(state, draft => {
      draft.importSuccessful = false
      draft.fetching = true
      delete draft.error.importDoorListTech
    })
  })

  .case(importDoorListTech.done, (state, { result }) => {
    return produce(state, draft => {
      draft.importSuccessful = true
      draft.fetching = false
    })
  })

  .case(importDoorListTech.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.importDoorListTech = (error as Response)
    })
  })
  .case(importDoorListAdmin.started, state => {
    return produce(state, draft => {
      draft.importSuccessful = false
      draft.fetching = true
      delete draft.error.importDoorListAdmin
    })
  })

  .case(importDoorListAdmin.done, (state, { result }) => {
    return produce(state, draft => {
      draft.importSuccessful = true
      draft.fetching = false
    })
  })

  .case(importDoorListAdmin.failed, (state, { error }) => {
    return produce(state, draft => {
      draft.fetching = false
      const errorStatus = (error as Response)
      if (errorStatus.status === 401) {
        draft.isLogged = false
        draft.user = undefined
      }
      draft.error.importDoorListAdmin = (error as Response)
    })
  })
  .case(setRedirectPath, (state, redirectPath) => {
    return ({ ...state, redirectPath })
  })
