import { createTheme, Theme } from '@mui/material'

export function getAppTheme (): Theme {
  const lightTheme = {
    typography: {
      fontSize: '12',
    },
    overrides: {
      MuiTabs: {
        root: {
          backgroundColor: '#f5f5f5',
          borderTop: '2px solid',
          borderColor: '#ebe7e9',
        },
      },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: '#ffffff',
            borderTop: '3px solid',
            borderColor: '#800000',
            color: '#800000',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
      MuiButton: {
        root: {
          border: '1px solid #800000',
          color: '#800000',
          minWidth: '0',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#fafafa',
          },
        },
        outlined: {
          borderColor: '#800000',
        },
        outlinedSecondary: {
          color: '#800000',
          '&:hover': {
            backgroundColor: '#fafafa',
            border: '1px solid #800000',
          },
        },
        outlinedPrimary: {
          color: '#ffffff',
          backgroundColor: '#800000',
          '&:hover': {
            backgroundColor: '#640000',
            border: '1px solid #640000',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#800000',
        dark: '#1F1A17',
      },
      secondary: {
        main: '#1F1A17',
      },
    },
  }

  const theme: Theme = createTheme(lightTheme)

  return theme
}
