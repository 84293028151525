import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@mui/styles'
import { Paper, Theme } from '@mui/material'

import { UserDataDto, DoorInfoDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { useDispatch, useSelector } from 'react-redux'

import { getDoorListInitiated } from '~store/user/user.actions'

import { getDoorList, onSuccess, onImportSuccessful } from '~store/user/user.selector'
import TableView from '~shared/components/TableView/TableView'
import TableViewDetail from '~shared/components/TableView/TableViewDetail'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    table: {
      width: '100%',
    },
    sortLabel: {
      color: theme.palette.primary.main,
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    margin: {
      margin: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(2),
    },
    width: {
      width: '100%',
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
)

interface Props {
  user?: UserDataDto
}

const Door: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const doors: DoorInfoDto[] | undefined = useSelector(getDoorList)

  const onSaveSuccess = useSelector(onSuccess)
  const onImportSuccess = useSelector(onImportSuccessful)

  const dispatch = useDispatch()
  const dispatchGetDoorList = useCallback(() => dispatch(getDoorListInitiated()), [dispatch])

  useEffect(() => {
    if (onSaveSuccess === 'createDoorProtocol' || onImportSuccess) {
      dispatchGetDoorList()
    }
  }, [onSaveSuccess, onImportSuccess])

  useEffect(() => {
    dispatchGetDoorList()
  }, [])

  const [doorList, setDoorList] = React.useState<DoorInfoDto[]>(doors || [])
  const [selectedDoor, setSelectedDoor] = React.useState<DoorInfoDto | null>(null)
  const [selectedDoorId, setSelectedDoorId] = React.useState<string | null>(null)

  useEffect(() => {
    doors && setDoorList(doors)
    if (doors && selectedDoorId) {
      const selDoor = doors.find(elem => elem.doorId === selectedDoorId)
      selDoor && setSelectedDoor(selDoor)
    }
  }, [doors])

  return (
    <>
      <Paper className={classes.root}>
        {!selectedDoor && <TableView
          doors={doorList} onSelectDoor={(door: DoorInfoDto) => {
            setSelectedDoor(door)
            setSelectedDoorId(door.doorId)
          }}
        />}
        {selectedDoor && <TableViewDetail
          door={selectedDoor} onDeselectDoor={() => {
            setSelectedDoor(null)
            setSelectedDoorId(null)
          }}
        />}
      </Paper>
    </>
  )
}
export default Door
