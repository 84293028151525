import actionCreatorFactory from 'typescript-fsa'

import {
  SessionForgotPasswordRequest,
  SessionResetPasswordRequest,
  SessionChangePasswordRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/SessionApi'

import {
  ResetUserTwoFactorAuthSecretRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/AdminActionsApi'

import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  UserDataDto, DoorInfoDto,
  LoginRequest, VpsInfoDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { UpdateUserDataRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/UserDataApi'
import {
  AddDoorProtocolOperationRequest, ExportDoorExcelFileForAdminRequest, ExportDoorExcelFileForTechUserRequest,
  GetDoorRequest, ImportDoorExcelFileForAdminRequest, ImportDoorExcelFileForTechUserRequest, ExportDoorQrCodesOperationRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/DoorApi'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

export interface Toast {
  type: Severity
  text?: string
  translate?: string
}

const actionCreator = actionCreatorFactory('LOGIN')

/*
 * Actions
 */
export const loginInitiated = actionCreator<LoginRequest>('LOGIN_INITIATED')
export const login = actionCreator.async<LoginRequest, undefined>('LOGIN')

export const logoutInitiated = actionCreator('LOGOUT_INITIATED')
export const logout = actionCreator.async<undefined, void>('LOGOUT')

export const forgotPasswordInitiated = actionCreator<SessionForgotPasswordRequest>('FORGOT_PASSWORD_INITIATED')
export const forgotPassword = actionCreator.async<SessionForgotPasswordRequest, ForgotPasswordRequest>('FORGOT_PASSWORD')

export const updatePasswordInitiated = actionCreator<SessionChangePasswordRequest>('UPDATE_USER_PASSWORD_INITIATED')
export const updatePassword = actionCreator.async<SessionChangePasswordRequest, ChangePasswordRequest>('UPDATE_USER_PASSWORD')

export const resetPasswordInitiated = actionCreator<SessionResetPasswordRequest>('RESET_USER_PASSWORD_INITIATED')
export const resetPassword = actionCreator.async<SessionResetPasswordRequest, ResetPasswordRequest >('RESET_USER_PASSWORD')

export const addToast = actionCreator<Toast>('ADD_TOAST')
export const removeLastToast = actionCreator('REMOVE_EARLIEST_TOAST')

export const getUserDataInitiated = actionCreator('GET_USER_DATA_INITIATED')
export const getUserData = actionCreator.async<undefined, UserDataDto>('GET_USER_DATA')

export const updateUserDataInitiated = actionCreator<UpdateUserDataRequest>('CHANGE_USER_DATA_INITIATED')
export const updateUserData = actionCreator.async<UpdateUserDataRequest, UserDataDto>('CHANGE_USER_DATA')

export const getDoorListInitiated = actionCreator('GET_DOOR_LIST_INITIATED')
export const getDoorList = actionCreator.async<undefined, DoorInfoDto[]>('GET_DOOR_LIST')

export const getDoorDataInitiated = actionCreator<GetDoorRequest>('GET_DOOR_DATA_INITIATED')
export const getDoorData = actionCreator.async<GetDoorRequest, DoorInfoDto>('GET_DOOR_DATA')

export const createDoorProtocolInitiated = actionCreator<AddDoorProtocolOperationRequest>('CREATE_DOOR_PROTOCOL_INITIATED')
export const createDoorProtocol = actionCreator.async<AddDoorProtocolOperationRequest, undefined>('CREATE_DOOR_PROTOCOL')

export const getVpsListInitiated = actionCreator('GET_VPS_LIST_INITIATED')
export const getVpsList = actionCreator.async<undefined, VpsInfoDto[]>('GET_VPS_LIST')

export const setLoggedIn = actionCreator<boolean>('SET_LOGGED_IN')

export const resetUserSecretInitiated = actionCreator<ResetUserTwoFactorAuthSecretRequest>('RESET_USER_SECRET_INITIATED')
export const resetUserSecret = actionCreator.async<ResetUserTwoFactorAuthSecretRequest, undefined>('RESET_USER_SECRET')

export const printDoorQRAdminInitiated = actionCreator<ExportDoorQrCodesOperationRequest>('PRINT_DOOR_QR_ADMIN_INITIATED')
export const printDoorQRAdmin = actionCreator.async<ExportDoorQrCodesOperationRequest, Blob>('PRINT_DOOR_QR_ADMIN')

export const exportDoorListAdminInitiated = actionCreator<ExportDoorExcelFileForAdminRequest>('EXPORT_DOOR_LIST_ADMIN_INITIATED')
export const exportDoorListAdmin = actionCreator.async<ExportDoorExcelFileForAdminRequest, Blob>('EXPORT_DOOR_LIST_ADMIN')

export const exportDoorListTechInitiated = actionCreator<ExportDoorExcelFileForTechUserRequest>('EXPORT_DOOR_LIST_TECH_INITIATED')
export const exportDoorListTech = actionCreator.async<ExportDoorExcelFileForTechUserRequest, Blob>('EXPORT_DOOR_LIST_TECH')

export const importDoorListAdminInitiated = actionCreator<ImportDoorExcelFileForAdminRequest>('IMPORT_DOOR_LIST_ADMIN_INITIATED')
export const importDoorListAdmin = actionCreator.async<ImportDoorExcelFileForAdminRequest, undefined>('IMPORT_DOOR_LIST_ADMIN')

export const importDoorListTechInitiated = actionCreator<ImportDoorExcelFileForTechUserRequest>('IMPORT_DOOR_LIST_TECH_INITIATED')
export const importDoorListTech = actionCreator.async<ImportDoorExcelFileForTechUserRequest, undefined>('IMPORT_DOOR_LIST_TECH')

export const setRedirectPath = actionCreator<string | undefined>('SET_REDIRECT_PATH')
