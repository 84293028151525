import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addToast, Toast, updatePasswordInitiated } from '~store/user/user.actions'
import { Button, DialogTitle, TextField, Theme, DialogContent, DialogActions, Grid } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

interface Props {
  isOpenCallback: (e: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      width: '100%',
      margin: '10px',
    },
  }),
)

const ChangePassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const dispatch = useDispatch()
  const dispatchChangePassword = useCallback((oldPassword: string, newPassword: string, oneTimePassword: string) => dispatch(updatePasswordInitiated({ changePasswordRequest: { oldPassword, newPassword, oneTimePassword } })), [dispatch])
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [oneTimePassword, setOneTimePassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const _onPasswordChange = () => {
    const code = oneTimePassword !== ''
    const samePasswords = newPassword === confirmPassword
    const setOldPassword = oldPassword !== ''
    const setNewPassword = newPassword !== ''
    if (code && samePasswords && setOldPassword && setNewPassword) {
      dispatchChangePassword(oldPassword, newPassword, oneTimePassword)
      props.isOpenCallback(false)
    } else {
      let text = t('messages:setOneTimeCode')
      if (!samePasswords) {
        text = t('messages:newPasswordError')
      }
      if (!setNewPassword) {
        text = t('messages:setNewPassword')
      }
      if (!setOldPassword) {
        text = t('messages:setOldPassword')
      }
      const toast: Toast = {
        type: Severity.error,
        text: text,
      }
      dispatchAddToast(toast)
    }
  }

  const _onCancelEdit = () => {
    props.isOpenCallback(false)
  }

  return (
    <div>
      <DialogTitle>{t('settings:changePassword')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:oldPassword')}
              type='password'
              onChange={(event) => setOldPassword(event.currentTarget.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:newPassword')}
              type='password'
              onChange={(event) => setNewPassword(event.currentTarget.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:confirmPassword')}
              type='password'
              onChange={(event) => setConfirmPassword(event.currentTarget.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('twoFactorAuthentification:activateTwoFactorAuthentificationSecurityCode')}
              type='password'
              onChange={(event) => setOneTimePassword(event.currentTarget.value)}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancelEdit} color='secondary'>
          {t('common:cancel')}
        </Button>
        <Button onClick={_onPasswordChange} color='primary' autoFocus>
          {t('settings:changePassword')}
        </Button>
      </DialogActions>
    </div>
  )
}

export default ChangePassword
