import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { EntityData } from '@3m5/crude-frontend/dist/shared/types/dataTypes'
import { useDispatch } from 'react-redux'
import { addToast, Toast } from '~store/user/user.actions'
import Env from '~config/envConfig'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

interface InjectedTableActionComponentProps {
  entity: string
  entityId: string
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}
const TableActionButtons: React.FC<InjectedTableActionComponentProps> = (props) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])

  const [showPopUp, setShowPopUp] = useState<boolean>(false)

  const userRole = localStorage.getItem('userRole')

  const basePath = Env.restBase

  const cancelDelete = () => {
    setShowPopUp(false)
  }

  const deleteSecret = () => {
    setShowPopUp(false)
    const url = `${basePath}/api/admin/2fa/${props.entityId}`
    fetch(url,
      {
        method: 'DELETE',
        credentials: 'include',
      })
      .then(() => {
        const toast: Toast = {
          type: Severity.error,
          text: t('messages:deleteSecretSuccess'),
        }
        dispatchAddToast(toast)
      })
  }

  const exportDoorFile = () => {
    const url = `${basePath}/api/door/export/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'exportDoors.xlsx'
        document.body.appendChild(a)
        a.click()
        a.remove()
        const toast: Toast = {
          type: Severity.error,
          text: t('messages:exportDoorExcelFileError'),
        }
        dispatchAddToast(toast)
      })
  }

  const onChangeFile = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const url = `${basePath}/api/door/import/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: files[0],
      })
      .then(() => {
        const toast: Toast = {
          type: Severity.error,
          text: t('messages:importDoorExcelFileError'),
        }
        dispatchAddToast(toast)
      })
  }

  const hiddenFileInput = React.useRef(null)

  const importDoorFile = () => {
    hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click()
  }

  return (
    <>
      {(
        (props.entity === 'user') &&
        (props.treeViewLevel <= 1) &&
        (userRole && userRole === 'PortalAdmin')
      ) &&
        <>
          <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => setShowPopUp(true)}>{t('settings:deleteSecret')}</Button>
          {showPopUp &&
            <Dialog
              open={showPopUp}
            >
              <div>
                <DialogTitle>{t('settings:deleteSecret')}</DialogTitle>
                <DialogContent>
                  {t('settings:deleteSecretHint')}
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelDelete} color='secondary'>
                    {t('common:cancel')}
                  </Button>
                  <Button onClick={deleteSecret} color='primary' autoFocus>
                    {t('settings:deleteSecret')}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>}
        </>}
      {(
        (props.entity === 'customer') &&
        (props.treeViewLevel <= 1) &&
        (userRole && userRole === 'PortalAdmin')
      ) &&
        <>
          <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={importDoorFile}>{t('common:import')}</Button>
          <input
            type='file'
            ref={hiddenFileInput}
            onInput={onChangeFile}
            style={{ display: 'none' }}
          />
          <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={exportDoorFile}>{t('common:export')}</Button>
        </>}
    </>
  )
}
export default TableActionButtons
