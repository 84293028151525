import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addToast, forgotPasswordInitiated, Toast } from '~store/user/user.actions'
import { Button, DialogTitle, TextField, DialogContent, DialogActions } from '@mui/material'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

interface Props {
  isOpenCallback: (e: boolean) => void
}

const ForgotPassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const dispatchForgotPassword = useCallback((mailAddress: string) => dispatch(forgotPasswordInitiated({ forgotPasswordRequest: { mailAddress } })), [dispatch])
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])

  const [mailAddress, setMailAddress] = useState<string>('')

  const _onPasswordReset = () => {
    if (mailAddress) {
      dispatchForgotPassword(mailAddress)
      props.isOpenCallback(false)
    } else {
      const toast: Toast = {
        type: Severity.error,
        text: t('messages:mailInputError'),
      }
      dispatchAddToast(toast)
    }
  }

  const _onCancelEdit = () => {
    props.isOpenCallback(false)
  }

  return (
    <div>
      <DialogTitle>{t('login:resetPassword')}</DialogTitle>
      <DialogContent>
        <TextField
          label={t('settings:email')}
          onChange={(event) => setMailAddress(event.currentTarget.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancelEdit} color='secondary'>
          {t('common:cancel')}
        </Button>
        <Button onClick={_onPasswordReset} color='primary' autoFocus>
          {t('login:buttonReset')}
        </Button>
      </DialogActions>
    </div>
  )
}

export default ForgotPassword
