// Only if targeting older browser
// Check docs/transforms.md for details
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import '~shared/translations/i18next'
import App from './screens/App/App'
import { rootReducer } from './store/root.reducer'
import { rootSaga } from './store/root.sagas'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  ),
)

sagaMiddleware.run(rootSaga)

export default function Bootstrap (): React.ReactElement {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  )
}

ReactDOM.render(<Bootstrap />, window.document.getElementById('root'))
