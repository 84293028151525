import React, { useCallback, useState } from 'react'
import './navigation.scss'
import NavigationItem from './NavigationItem'
import NavigationHeader from './NavigationHeader'
import NavigationBar from './NavigationBar/NavigationBar'
import { AppSetting } from '~shared/models/Model'

import {
  UserDataDto,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

interface SideNavProps {
  items: AppSetting[]
  doLogout: () => void
  user?: UserDataDto
  hideNav?: boolean
}

const renderItems = (items:AppSetting[], user: UserDataDto | null, collapsed: boolean) : React.ReactNode => {
  const navItems = items.map((item: AppSetting, id: number) => {
    if (user && item && item.roles.includes(user.role)) {
      return (
        <NavigationItem
          item={item}
          key={id}
          collapsed={collapsed}
        />
      )
    }
  },
  )
  return (
    <>
      {navItems}
    </>
  )
}

const NavigationContainer: React.FC<SideNavProps> = (props) => {
  const [collapsed, setCollapsed] = useState(true)
  const doLogoutProps: () => void = props.doLogout
  const toggle = useCallback(
    () => {
      setCollapsed(!collapsed)
    },
    [collapsed, setCollapsed],
  )
  const doLogout = useCallback(
    () => {
      doLogoutProps()
    },
    [doLogoutProps],
  )
  return (
    <div className='navRoot'>
      {
        !props.hideNav && props.user &&
          <div className={`navContainer ${collapsed ? '' : 'open'} `}>
            <NavigationHeader
              expandedImage='/beweng-white.png'
              collapsedImage='/beweng.png'
              collapsed={collapsed}
              toggle={toggle}
            />
            {renderItems(props.items, props.user, collapsed)}
          </div>
      }
      {
        !props.hideNav && props.user &&
          <NavigationBar
            doLogout={doLogout}
            user={props.user}
          />
      }
      <div className={`navContentContainer${!collapsed ? ' open' : ''}`}>
        <div className='navContentContainer__content'>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default NavigationContainer
