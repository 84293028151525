import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, CssBaseline, TextField, Grid, Typography, Container, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'
import { isLogged } from '~store/user/user.selector'
import * as userActions from '~store/user/user.actions'
import {
  LoginRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

import ForgotPassword from './ForgotPassword'
import AddTwoFactorAuthentification, { InitSecret, QRCode } from './AddTwoFactorAuthentification'
import TwoFactorAuthentification from './TwoFactorAuthentification'

import Env from '~config/envConfig'
import { addToast, Toast } from '~store/user/user.actions'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  image: {
    height: '70px',
  },
}))

const Login = () => {
  const dispatch = useDispatch()

  const dispatchGetUser = useCallback(() => dispatch(userActions.getUserDataInitiated()), [dispatch])
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])

  const userIsLogged: boolean = useSelector(isLogged)

  const classes = useStyles()
  const { t } = useTranslation()

  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [secretStatus, setSecretStatus] = useState<string>('')
  const [showForgotPasswortModal, setShowForgotPasswortModal] = useState<boolean>(false)
  const [showTwoFactorModal, setShowTwoFactorModal] = useState<boolean>(false)
  const [showResetCodeModal, setShowResetCodeModal] = useState<boolean>(false)
  const [qrCode, setQrCode] = useState<QRCode>({ name: '', secretQrCodeBase64: '' })

  const _onLogin = () => {
    const loginRequest: LoginRequest = {
      login,
      password,
    }
    const basePath = Env.restBase
    const url = `${basePath}/api/session/login`
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(loginRequest),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(res => res.json())
      .then(json => {
        switch (json.result) {
          case 'Success':
            dispatch(userActions.setLoggedIn(true))
            dispatchGetUser()
            break
          case 'Error':
            dispatchAddToast({
              type: Severity.error,
              text: t('messages:loginError'),
            })
            break
          case 'TwoFactorInitRequired':
            getQRCode(json.result)
            // open modal
            setShowTwoFactorModal(!showTwoFactorModal)
            break
          default:
            setSecretStatus(json.result)
            // open modal
            setShowTwoFactorModal(!showTwoFactorModal)
            break
        }
      })
  }

  const getQRCode = (status: string) => {
    const basePath = Env.restBase
    const timestamp = Date.now()
    const url = `${basePath}/api/session/2fa/init`
    const data: InitSecret = {
      login,
      password: password,
      deviceName: timestamp.toString(),
    }
    fetch(url,
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(res => res.json())
      .then(json => {
        setQrCode(json)
        setSecretStatus(status)
      })
  }

  useEffect(() => {
    dispatchGetUser()
  }, [userIsLogged],
  )

  const _onForgotPasswordClick = () => {
    setShowForgotPasswortModal(!showForgotPasswortModal)
  }

  const _onResetCode = (value: boolean) => {
    setShowTwoFactorModal(false)
    if (value) {
      getQRCode(secretStatus)
      setSecretStatus('TwoFactorInitRequired')
      setShowResetCodeModal(true)
    }
  }

  const _onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      _onLogin()
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img src='/beweng.png' className={classes.image} />
        <form className={classes.form} noValidate>
          <TextField
            margin='normal'
            required
            fullWidth
            id='login'
            label={t('login:email')}
            name='login'
            autoComplete='login'
            autoFocus
            value={login}
            onChange={(event) => setLogin(event.currentTarget.value)}
            onKeyPress={_onKeyPress}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label={t('login:password')}
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            onKeyPress={_onKeyPress}
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={_onLogin}
          >
            {t('login:login')}
          </Button>
          <Grid container>
            <Grid item>
              <Typography
                className={classes.link}
                onClick={_onForgotPasswordClick}
              >{t('login:forgotPassword')}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        open={showTwoFactorModal}
      >
        {(secretStatus === 'TwoFactorInitRequired') &&
          <AddTwoFactorAuthentification
            login={login}
            password={password}
            qrCode={qrCode}
            isOpenCallback={(value: boolean) => setShowTwoFactorModal(value)}
          />}
        {(secretStatus === 'TwoFactorActivationRequired') &&
          <TwoFactorAuthentification
            login={login}
            password={password}
            createNewAllowed
            isOpenCallback={(value: boolean) => setShowTwoFactorModal(value)}
            resetCallback={_onResetCode}
          />}
        {(secretStatus === 'TwoFactorCodeRequired') &&
          <TwoFactorAuthentification
            login={login}
            password={password}
            createNewAllowed={false}
            isOpenCallback={(value: boolean) => setShowTwoFactorModal(value)}
            resetCallback={_onResetCode}
          />}
      </Dialog>
      <Dialog
        open={showResetCodeModal}
      >
        {(qrCode) &&
          <AddTwoFactorAuthentification
            login={login}
            password={password}
            qrCode={qrCode}
            isOpenCallback={(value: boolean) => setShowResetCodeModal(value)}
          />}
      </Dialog>
      <Dialog
        open={showForgotPasswortModal}
      >
        <ForgotPassword isOpenCallback={(value: boolean) => setShowForgotPasswortModal(value)} />
      </Dialog>
    </Container>
  )
}
export default Login
