import React, { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment, Switch,
  TextField, Theme,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import ClearIcon from '@mui/icons-material/Clear'

import { appLayout } from '~screens/App/App'

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    margin: theme.spacing(),
    width: 250,
  },
  textfield: {
    marginLeft: theme.spacing(),
  },
  hidden: {
    display: 'none',
  },
  switch: {
    width: 250,
    margin: theme.spacing(),
  },
}))

interface Props {
  name: string
  value?: string
  onChangeValue: (value?: string) => void
}

const TableViewDetailProtocolEditError: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const type = 'switch'

  const [error, setError] = React.useState<boolean>(!!props.value)
  const [value, setValue] = React.useState<string | undefined>(props.value)

  useEffect(() => {
    if (value === '') {
      props.onChangeValue(undefined)
    } else {
      props.onChangeValue(value)
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(!error)
  }

  return (
    <>
      {type === 'switch'
        ? <div className={classes.switch}>
          <FormControlLabel
            control={
              <Switch
                checked={!error}
                onChange={handleChange}
                name='checkedB'
                color='primary'
              />
            }
            label={t(`doors:${props.name}`)}
          />
        </div>
        : <div className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!error}
                onChange={handleChange}
                name='checkedB'
                color='primary'
              />
            }
            label={t(`doors:${props.name}`)}
          />
        </div>}
      <TextField
        className={`${classes.textfield} ${!error && classes.hidden}`}
        id={`id${props.name}`}
        name={`name${props.name}`}
        key={`key${props.name}`}
        InputLabelProps={{ shrink: true }}
        label={t('doors:error')}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
        }}
        margin='normal'
        required={error}
        variant={appLayout?.textfield?.type}
        autoFocus={false}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => {
                props.onChangeValue(undefined)
              }}
              position='end'
            >
              <IconButton
                aria-label='delete'
              >
                {appLayout?.textfield?.deleteIcon === 'delete' &&
                  <DeleteIcon />}
                {appLayout?.textfield?.deleteIcon === 'clear' &&
                  <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
export default TableViewDetailProtocolEditError
