import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@mui/styles'
import {
  TableContainer,
  Button,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  TableBody,
  TablePagination,
  FormControl,
  InputLabel,
  Input,
  Select, Chip, MenuItem,
  InputAdornment, TextField, Checkbox, Divider,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'

import { addToast, exportDoorListTechInitiated, importDoorListTechInitiated, printDoorQRAdminInitiated, Toast } from '~store/user/user.actions'

import { DoorInfoDto, UserRoleDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'

import { appLayout } from '~screens/App/App'

import TableViewRow from './TableRowView'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '~store/user/user.selector'
import { ExportDoorExcelFileForTechUserRequest, ImportDoorExcelFileForTechUserRequest, ExportDoorQrCodesOperationRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/DoorApi'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    table: {
      width: '100%',
    },
    sortLabel: {
      color: theme.palette.primary.main,
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(),
    },
    margin: {
      margin: theme.spacing(2),
    },
    marginRight: {
      marginRight: theme.spacing(),
    },
    padding: {
      padding: theme.spacing(2),
    },
    width: {
      width: '100%',
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    filter: {
      width: '100%',
      display: 'flex',
      marginRight: theme.spacing(2),
      alignItems: 'end',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    iconButton: {
      marginBottom: theme.spacing(2),
    },
    searchField: {
      margin: theme.spacing(),
    },
  }),
)

interface Props {
  doors?: DoorInfoDto[]
  onSelectDoor?: (door: DoorInfoDto) => void
}

type FilterExistObject = {
  [key: string]: any
}

type ExportList = {
  [key: string]: boolean
}

const TableView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const user = useSelector(getUser)

  const dispatch = useDispatch()
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])
  const dispatchExportDoorListTech = useCallback((doorShortIdArray: ExportDoorExcelFileForTechUserRequest) => dispatch(exportDoorListTechInitiated(doorShortIdArray)), [dispatch])
  const dispatchPrintDoorQRAdmin = useCallback((doorShortIdArray: ExportDoorQrCodesOperationRequest) => dispatch(printDoorQRAdminInitiated(doorShortIdArray)), [dispatch])
  const dispatchImportDoorListTech = useCallback((importFile: ImportDoorExcelFileForTechUserRequest) => dispatch(importDoorListTechInitiated(importFile)), [dispatch])

  const filterStrings = ['customer', 'city', 'building', 'buildingPart', 'floor']

  const createFilter = (doors: DoorInfoDto[]) => {
    const filterObject: FilterExistObject = {
      customer: [],
      city: [],
      building: [],
      buildingPart: [],
      floor: [],
    }
    const existObject: FilterExistObject = {}
    doors.forEach((door: DoorInfoDto) => {
      filterStrings.forEach((filter: string) => {
        existObject[filter] = filterObject[filter].find((elem: string) => elem === door[filter])
        if (!existObject[filter] && door[filter]) {
          filterObject[filter].push(door[filter])
        }
      })
    })
    setCustomer(filterObject.customer)
    setCity(filterObject.city)
    setBuilding(filterObject.building)
    setBuildingPart(filterObject.buildingPart)
    setFloor(filterObject.floor)
  }

  const sortDoors = (newFilteredDoorList: DoorInfoDto[]) => {
    const sortDoors = [...newFilteredDoorList]

    if (sortDoors.length > 1 && orderBy) {
      sortDoors.sort((a, b) => {
        if (a[orderBy] && b[orderBy]) {
          const fa = a[orderBy].toLowerCase()
          const fb = b[orderBy].toLowerCase()

          if ((fa < fb && order === 'desc') || (fa > fb && order === 'asc')) {
            return -1
          }
          if ((fa > fb && order === 'desc') || (fa < fb && order === 'asc')) {
            return 1
          }
        }
        return 0
      })
    }
    setDoorList([...sortDoors])
  }

  const filterDoors = () => {
    // Customer
    let newFilteredDoorList: DoorInfoDto[] = []
    props.doors && props.doors.forEach((door: DoorInfoDto) => {
      // Customer
      if (selectedCustomer.length > 0) {
        selectedCustomer.forEach((customer: string) => {
          if (door.customer && door.customer === customer) {
            newFilteredDoorList.push(door)
          }
        })
      } else {
        newFilteredDoorList.push(door)
      }
    })
    let tempDoorList = newFilteredDoorList
    // City
    newFilteredDoorList = []
    tempDoorList && tempDoorList.forEach((door: DoorInfoDto) => {
      // City
      if (selectedCity.length > 0) {
        selectedCity.forEach((city: string) => {
          if (door.city && door.city === city) {
            newFilteredDoorList.push(door)
          }
        })
      } else {
        newFilteredDoorList.push(door)
      }
    })
    tempDoorList = newFilteredDoorList
    // Building
    newFilteredDoorList = []
    tempDoorList && tempDoorList.forEach((door: DoorInfoDto) => {
      if (selectedBuilding.length > 0) {
        selectedBuilding.forEach((building: string) => {
          if (door.building && door.building === building) {
            newFilteredDoorList.push(door)
          }
        })
      } else {
        newFilteredDoorList.push(door)
      }
    })
    tempDoorList = newFilteredDoorList
    // BuildingPart
    newFilteredDoorList = []
    tempDoorList && tempDoorList.forEach((door: DoorInfoDto) => {
      if (selectedBuildingPart.length > 0) {
        selectedBuildingPart.forEach((buildingPart: string) => {
          if (door.buildingPart && door.buildingPart === buildingPart) {
            newFilteredDoorList.push(door)
          }
        })
      } else {
        newFilteredDoorList.push(door)
      }
    })
    tempDoorList = newFilteredDoorList
    // Floor
    newFilteredDoorList = []
    tempDoorList && tempDoorList.forEach((door: DoorInfoDto) => {
      if (selectedFloor.length > 0) {
        selectedFloor.forEach((floor: string) => {
          if (door.floor && door.floor === floor) {
            newFilteredDoorList.push(door)
          }
        })
      } else {
        newFilteredDoorList.push(door)
      }
    })
    tempDoorList = newFilteredDoorList

    // Search
    newFilteredDoorList = []
    tempDoorList && tempDoorList.forEach((door: DoorInfoDto) => {
      if (search && search !== '') {
        const regex = new RegExp(search, 'i')
        if (
          door.name.match(regex) ||
          (door.building && door.building.match(regex)) ||
          (door.city && door.city.match(regex)) ||
          (door.description && door.description.match(regex)) ||
          (door.buildingPart && door.buildingPart.match(regex)) ||
          (door.floor && door.floor.match(regex)) ||
          (door.customer && door.customer.match(regex))
        ) {
          newFilteredDoorList.push(door)
        }
      } else {
        newFilteredDoorList.push(door)
      }
    })
    sortDoors(newFilteredDoorList)
    createFilter(newFilteredDoorList)
  }

  const [doorList, setDoorList] = React.useState<DoorInfoDto[]>(props.doors ? props.doors : [])
  const [pagedDoorList, setPagedDoorList] = React.useState<DoorInfoDto[]>(props.doors ? props.doors : [])

  const [search, setSearch] = React.useState<string>('')

  const [city, setCity] = React.useState<string[]>([])
  const [customer, setCustomer] = React.useState<string[]>([])
  const [selectedCity, setSelectedCity] = React.useState<string[]>([])
  const [selectedCustomer, setSelectedCustomer] = React.useState<string[]>([])
  const [building, setBuilding] = React.useState<string[]>([])
  const [selectedBuilding, setSelectedBuilding] = React.useState<string[]>([])
  const [buildingPart, setBuildingPart] = React.useState<string[]>([])
  const [selectedBuildingPart, setSelectedBuildingPart] = React.useState<string[]>([])
  const [floor, setFloor] = React.useState<string[]>([])
  const [selectedFloor, setSelectedFloor] = React.useState<string[]>([])

  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [orderBy, setOrderBy] = useState<string>('')
  const [pageSize, setPageSize] = useState<number | undefined>(25)
  const [page, setPage] = useState<number>(0)

  const [exportList, setExportList] = useState<ExportList>({})
  const [exportArray, setExportArray] = useState<string[]>([])

  useEffect(() => {
    let pagedList = doorList
    if (pageSize) {
      pagedList = doorList.slice(page * pageSize, (page * pageSize) + pageSize)
    }
    setPagedDoorList(pagedList)
  }, [doorList, pageSize, page])

  useEffect(() => {
    props.doors && setDoorList(props.doors)
    props.doors && createFilter(props.doors)
  }, [props.doors])

  useEffect(() => {
    sortDoors(doorList)
  }, [order, orderBy])

  useEffect(() => {
    filterDoors()
  }, [selectedCustomer, selectedCity, selectedBuilding, selectedBuildingPart, selectedFloor, search])

  const clearFilter = () => {
    setSelectedCustomer([])
    setSelectedCity([])
    setSelectedBuilding([])
    setSelectedBuildingPart([])
    setSelectedFloor([])
    setSearch('')
  }

  const handleChangeCustomer = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCustomer(event.target.value as string[])
  }

  const handleChangeCity = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCity(event.target.value as string[])
  }

  const handleChangeBuilding = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBuilding(event.target.value as string[])
  }

  const handleChangeBuildingPart = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBuildingPart(event.target.value as string[])
  }

  const handleChangeFloor = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFloor(event.target.value as string[])
  }

  // Export Doors
  const exportAllDoors = () => {
    const newList: ExportList = {}
    doorList.forEach((elem: DoorInfoDto) => {
      newList[elem.shortId] = true
    })
    setExportList(newList)
  }

  const exportNothing = () => {
    const newList: ExportList = {}
    doorList.forEach((elem: DoorInfoDto) => {
      newList[elem.shortId] = false
    })
    setExportList(newList)
  }

  useEffect(() => {
    const newArray: string[] = []
    Object.keys(exportList).forEach((key) => {
      if (exportList[key]) {
        newArray.push(key)
      }
    })
    setExportArray(newArray)
  }, [exportList])

  useEffect(() => {
    exportNothing()
  }, [doorList])

  const onExportAllClick = () => {
    if (doorList.length === exportArray.length) {
      exportNothing()
    } else {
      exportAllDoors()
    }
  }

  const changeExportList = (shortId: string, value: boolean) => {
    const newList = { ...exportList }
    newList[shortId] = value
    setExportList(newList)
  }

  const printDoorQR = () => {
    if (exportArray.length === 0) {
      const toast: Toast = {
        type: Severity.error,
        text: t('messages:noDoorsSelected'),
      }
      dispatchAddToast(toast)
    } else {
      const request: ExportDoorQrCodesOperationRequest = {
        exportDoorQrCodesRequest: { doorShortIds: exportArray },
      }
      dispatchPrintDoorQRAdmin(request)
    }
  }

  const exportDoorFile = () => {
    if (exportArray.length === 0) {
      const toast: Toast = {
        type: Severity.error,
        text: t('messages:noDoorsSelected'),
      }
      dispatchAddToast(toast)
    } else {
      dispatchExportDoorListTech({ exportDoorRequest: { doorShortIds: exportArray } })
    }
  }
  const onChangeFile = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    dispatchImportDoorListTech({ body: files[0] })
  }

  const hiddenFileInput = React.useRef(null)

  const importDoorFile = () => {
    hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click()
  }

  return (
    <>
      {/* Export / Import */}
      {user && user.role === UserRoleDto.TechUser &&
        <div className={classes.buttonContainer}>
          <div>
            <Button className={classes.marginRight} size='small' onClick={exportDoorFile} color='primary'>
              {t('common:export')}
            </Button>
            <Button size='small' onClick={importDoorFile} color='primary'>
              {t('common:import')}
            </Button>
            <input
              type='file'
              ref={hiddenFileInput}
              onInput={onChangeFile}
              style={{ display: 'none' }}
            />
          </div>
          <Button size='small' onClick={printDoorQR} color='primary'>
            {t('common:print')}
          </Button>
        </div>}

      <Divider className={classes.root} />

      {/* Suche */}
      <div className={classes.searchField}>
        <TextField
          id='search'
          autoFocus
          label={t('crm:search')}
          name='name'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={search}
          onChange={(event => setSearch(event.target.value))}
          margin='dense'
          variant={appLayout.textfield?.type}
        />
      </div>
      {/* Filter */}
      <div className={classes.filter}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id='multiple-customer-label'>{t('doors:customer')}</InputLabel>
            <Select
              labelId='multiple-customer-label'
              id='multiple-customer'
              multiple
              variant={appLayout.textfield?.type}
              value={selectedCustomer}
              onChange={handleChangeCustomer}
              input={<Input id='select-multiple-customer' />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {customer.map((element: string) => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl className={classes.formControl}> */}
          {/*  <InputLabel id='multiple-city-label'>{t('doors:city')}</InputLabel> */}
          {/*  <Select */}
          {/*    labelId='multiple-city-label' */}
          {/*    id='multiple-city' */}
          {/*    multiple */}
          {/*    variant={appLayout.textfield?.type} */}
          {/*    value={selectedCity} */}
          {/*    onChange={handleChangeCity} */}
          {/*    input={<Input id='select-multiple-city' />} */}
          {/*    renderValue={(selected) => ( */}
          {/*      <div className={classes.chips}> */}
          {/*        {(selected as string[]).map((value) => ( */}
          {/*          <Chip key={value} label={value} className={classes.chip} /> */}
          {/*        ))} */}
          {/*      </div> */}
          {/*    )} */}
          {/*  > */}
          {/*    {city.map((element: string) => ( */}
          {/*      <MenuItem key={element} value={element}> */}
          {/*        {element} */}
          {/*      </MenuItem> */}
          {/*    ))} */}
          {/*  </Select> */}
          {/* </FormControl> */}
          <FormControl className={classes.formControl}>
            <InputLabel id='multiple-building-label'>{t('doors:building')}</InputLabel>
            <Select
              labelId='multiple-building-label'
              id='multiple-building'
              multiple
              variant={appLayout.textfield?.type}
              value={selectedBuilding}
              onChange={handleChangeBuilding}
              input={<Input id='select-multiple-building' />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {building.map((element: string) => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl className={classes.formControl}> */}
          {/*  <InputLabel id='multiple-buildingPart-label'>{t('doors:buildingPart')}</InputLabel> */}
          {/*  <Select */}
          {/*    labelId='multiple-buildingPart-label' */}
          {/*    id='multiple-buildingPart' */}
          {/*    multiple */}
          {/*    value={selectedBuildingPart} */}
          {/*    onChange={handleChangeBuildingPart} */}
          {/*    variant={appLayout.textfield?.type} */}
          {/*    input={<Input id='select-multiple-buildingPart' />} */}
          {/*    renderValue={(selected) => ( */}
          {/*      <div className={classes.chips}> */}
          {/*        {(selected as string[]).map((value) => ( */}
          {/*          <Chip key={value} label={value} className={classes.chip} /> */}
          {/*        ))} */}
          {/*      </div> */}
          {/*    )} */}
          {/*  > */}
          {/*    {buildingPart.map((element: string) => ( */}
          {/*      <MenuItem key={element} value={element}> */}
          {/*        {element} */}
          {/*      </MenuItem> */}
          {/*    ))} */}
          {/*  </Select> */}
          {/* </FormControl> */}
          {/* <FormControl className={classes.formControl}> */}
          {/*  <InputLabel id='multiple-floor-label'>{t('doors:floor')}</InputLabel> */}
          {/*  <Select */}
          {/*    labelId='multiple-floor-label' */}
          {/*    id='multiple-floor' */}
          {/*    multiple */}
          {/*    value={selectedFloor} */}
          {/*    onChange={handleChangeFloor} */}
          {/*    variant={appLayout.textfield?.type} */}
          {/*    input={<Input id='select-multiple-floor' />} */}
          {/*    renderValue={(selected) => ( */}
          {/*      <div className={classes.chips}> */}
          {/*        {(selected as string[]).map((value) => ( */}
          {/*          <Chip key={value} label={value} className={classes.chip} /> */}
          {/*        ))} */}
          {/*      </div> */}
          {/*    )} */}
          {/*  > */}
          {/*    {floor.map((element: string) => ( */}
          {/*      <MenuItem key={element} value={element}> */}
          {/*        {element} */}
          {/*      </MenuItem> */}
          {/*    ))} */}
          {/*  </Select> */}
          {/* </FormControl> */}
        </div>
        <Button className={classes.iconButton} size='small' onClick={clearFilter}>
          <ClearIcon />
        </Button>
      </div>

      <Divider className={classes.root} />

      {/* Table */}
      <TableContainer>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              {user && user.role === UserRoleDto.TechUser &&
                <TableCell padding='checkbox' key='head-checkbox'>
                  <Checkbox
                    checked={doorList.length === exportArray.length}
                    onChange={onExportAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>}
              <TableCell align='left' key='head-collapse' style={{ width: '50px' }} />
              <TableCell align='left' key='head-customer'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'customer'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('customer')
                    }}
                  >
                    {t('doors:customer')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-shortId'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'shortId'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('shortId')
                    }}
                  >
                    {t('doors:shortId')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-name'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('name')
                    }}
                  >
                    {t('doors:name')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-description'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'description'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('description')
                    }}
                  >
                    {t('doors:description')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-city'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'city'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('city')
                    }}
                  >
                    {t('doors:city')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-building'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'building'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('building')
                    }}
                  >
                    {t('doors:building')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-buildingPart'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'buildingPart'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('buildingPart')
                    }}
                  >
                    {t('doors:buildingPart')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='left' key='head-floor'>
                <Tooltip title={t('common:sort')} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === 'floor'}
                    direction={order}
                    className={classes.sortLabel}
                    onClick={() => {
                      setOrder(order === 'desc' ? 'asc' : 'desc')
                      setOrderBy('floor')
                    }}
                  >
                    {t('doors:floor')}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align='center' key='head-lastProtocol' colSpan={2}>
                <TableSortLabel
                  active={orderBy === 'lastProtocol'}
                  direction={order}
                  className={classes.sortLabel}
                >
                  {t('doors:lastProtocol')}
                </TableSortLabel>
              </TableCell>
              {user && user.role === UserRoleDto.TechUser && <TableCell align='left' key='head-action' style={{ width: '100px' }} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedDoorList && pagedDoorList.map((door: DoorInfoDto, index: number) => (
              <>
                <TableViewRow
                  door={door}
                  index={index}
                  exportDoor={exportList[door.shortId]}
                  changeExportDoor={(value: boolean) => changeExportList(door.shortId, value)}
                  onSelectDoor={(door: DoorInfoDto) => props.onSelectDoor && props.onSelectDoor(door)}
                />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pageSize &&
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={doorList.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={(event: any, page: number) => {
            setPage(page)
          }}
          onChangeRowsPerPage={(event: any) => {
            setPageSize(event.target.value)
          }}
        />}
    </>
  )
}
export default TableView
