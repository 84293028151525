import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { addToast, Toast } from '~store/user/user.actions'
import { useDispatch, useSelector } from 'react-redux'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { getToasts } from '~store/user/user.selector'
import { useTranslation } from 'react-i18next'

const SnackbarComponent: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // messages aus dem Crude anzeigen
  const { enqueueSnackbar } = useSnackbar()
  const message: Toast | undefined = useSelector(getToasts)

  useEffect(() => {
    const type = (message && message.type) || Severity.info
    message && enqueueSnackbar(
      message.text || (message.translate && t(message.translate)),
      {
        variant: type,
      })
  }, [message],
  )

  useEffect(() => {
    window.addEventListener('CrudeMessages', (({ detail }: CustomEvent) => {
      if (detail.eventType !== 'load') {
        const message: Toast = {
          text: detail.message,
          type: detail.severity,
        }
        dispatch(addToast(message))
      }
    }) as EventListener)
  },
  [],
  )

  return (
    <>
    </>
  )
}

export default SnackbarComponent
