import { FetchAPI } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api'

const xsrfTokenKey = 'XSRF-TOKEN'

// in the unlikely case of "sessionStorage.setItem(undefined)" -> undefined will be set as string
// this should not happen, but it did while using aws functions which should have returned a string
// to prevent corrupt headers, we have to cleanup the getters or setters

export const GetXsrfToken = () => {
  const xsrfToken = sessionStorage.getItem(xsrfTokenKey)
  return xsrfToken === 'undefined' ? null : xsrfToken
}
export const SetXsrfToken = (token: string) => sessionStorage.setItem(xsrfTokenKey, token)

/**
 * Extended fetch API method to handles:
 * - XSRF tokens
 */
const customFetchApi = (fetch: FetchAPI): FetchAPI => async (url: RequestInfo, init: RequestInit | undefined): Promise<Response> => {
  const xsrfToken = GetXsrfToken()

  const requestInit = {
    ...init,
    credentials: 'include' as const, // same-origin
    headers: {
      ...(init ? init.headers : undefined),
      ...(xsrfToken ? { 'X-XSRF-TOKEN': xsrfToken } : undefined),
    },
  }

  // execute request
  const response = await fetch(url, requestInit)

  // check if we have a XSRF token in the http response header
  const newXsrfToken = response.headers.get('X-XSRF-TOKEN')
  if (newXsrfToken) SetXsrfToken(newXsrfToken)
  return response
}

export default customFetchApi
