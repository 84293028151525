import { combineReducers } from 'redux'
import { UserState, userReducer } from './user/user.reducer'

export interface ApiError {
  [key: string]: Response | Error | {} | undefined
}

export interface RootState {
  userStore: UserState
}

export const rootReducer = combineReducers<RootState>({
  userStore: userReducer,
})
