import * as React from 'react'

import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import { createStyles } from '@mui/styles'
import { ListItem, ListItemProps } from '@mui/material'

interface Props extends ListItemProps {
  to: LocationDescriptor
}

export class ListItemLink extends React.PureComponent<Props> {
  render () {
    return <ListItem {...this.props} component={props => <Link to={this.props.to} {...props} />} />
  }
}
