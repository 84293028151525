import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addToast, Toast, resetPasswordInitiated } from '~store/user/user.actions'
import { Button, TextField, Container, CssBaseline } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useHistory } from 'react-router-dom'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

interface Props {
  token: string
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  image: {
    height: '70px',
  },
}))

const ResetPassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const dispatchResetPassword = useCallback((newPassword: string, token: string) => dispatch(resetPasswordInitiated({ resetPasswordRequest: { newPassword, token } })), [dispatch])
  const dispatchAddToast = useCallback((toast: Toast) => dispatch(addToast(toast)), [dispatch])

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const _onSetPassword = () => {
    if (newPassword === confirmPassword) {
      dispatchResetPassword(newPassword, props.token)
      history.push('/login')
    } else {
      const toast: Toast = {
        type: Severity.error,
        text: t('messages:setPasswordError'),
      }
      dispatchAddToast(toast)
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>

        <form className={classes.form} noValidate>
          <TextField
            margin='normal'
            required
            fullWidth
            label={t('settings:newPassword')}
            type='password'
            autoFocus
            value={newPassword}
            onChange={(event) => setNewPassword(event.currentTarget.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            label={t('settings:confirmPassword')}
            type='password'
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={_onSetPassword}
          >
            {t('login:setPassword')}
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default ResetPassword
