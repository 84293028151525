import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import DashboardComponent from '~shared/components/DashboardComponent/DashboardComponent'
import VPSComponent from '~shared/components/DashboardComponent/VPSComponent'
import { AppSetting } from '~shared/models/Model'
import { useDispatch, useSelector } from 'react-redux'
import { UserDataDto, VpsInfoDto, UserRoleDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { getVpsListInitiated } from '~store/user/user.actions'
import { getVpsList } from '~store/user/user.selector'

interface Props {
  settings: AppSetting[]
  user?: UserDataDto
}

const Dashboard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [settings, setSettings] = useState<AppSetting[]>(props.settings)
  const vps: VpsInfoDto[] = useSelector(getVpsList)

  useEffect(() => {
    setSettings(props.settings)
  }, [props])

  const dispatch = useDispatch()
  const dispatchGetVpsList = useCallback(() => dispatch(getVpsListInitiated()), [dispatch])

  useEffect(() => {
    dispatchGetVpsList()
  }, [])

  return (
    <div className='root'>
      <Grid container spacing={3}>
        {(props.user && props.user.role !== UserRoleDto.User && props.user.role !== UserRoleDto.TechUser) ? (
          <>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {t('common:administration')}
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={3}>
              {settings.map((app: AppSetting, index: number) => {
                if (app && props.user && app.roles && app.roles.includes(props.user.role) && app.line === 1) {
                  return (
                    <Grid item xs={3} key={index}>
                      <DashboardComponent
                        title={app.title}
                        path={app.path}
                        icon={app.icon}
                        desc={app.desc}
                        img={app.img}
                      />
                    </Grid>
                  )
                }
              })}
            </Grid>
          </>)
          : (
            <div />
          )}
        {vps && vps.length !== 0 && props.user &&
          <>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {t('common:quickAccess')}
              </Typography>
            </Grid>
            <VPSComponent vps={vps} user={props.user} />
          </>}
        {props.user && (props.user.role !== UserRoleDto.PortalAdmin) &&
          <>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {t('common:editDoor')}
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={3}>
              {settings.map((app: AppSetting, index: number) => {
                if (app.line === 2) {
                  return (
                    <Grid item xs={3} key={index}>
                      <DashboardComponent title={app.title} path={app.path} icon={app.icon} desc={app.desc} img={app.img} />
                    </Grid>
                  )
                }
              })}
            </Grid>
          </>}
        <Grid item xs={12}>
          <Typography variant='h6'>
            {t('common:editProfile')}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={3}>
          {settings.map((app: AppSetting, index: number) => {
            if (app.line === 3) {
              return (
                <Grid item xs={3} key={index}>
                  <DashboardComponent title={app.title} path={app.path} icon={app.icon} desc={app.desc} img={app.img} />
                </Grid>
              )
            }
          })}
        </Grid>
      </Grid>
    </div>
  )
}
export default Dashboard
