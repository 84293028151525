import Env from '../config/envConfig'

import {
  SessionApi as SessionApiClass,
  UserDataApi as UserDataApiClass,
  AdminActionsApi as AdminActionsApiClass,
  VpsApi as VpsApiClass,
  DoorApi as DoorApiClass,
  Configuration,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api'
import customFetchApi from './customFetchApi'

const BASE_PATH_API = Env.restBase

export const SessionApi = new SessionApiClass(new Configuration({
  basePath: BASE_PATH_API,
  fetchApi: customFetchApi(fetch),
}))
export const UserDataApi = new UserDataApiClass(new Configuration({
  basePath: BASE_PATH_API,
  fetchApi: customFetchApi(fetch),
}))
export const VpsApi = new VpsApiClass(new Configuration({
  basePath: BASE_PATH_API,
  fetchApi: customFetchApi(fetch),
}))
export const DoorApi = new DoorApiClass(new Configuration({
  basePath: BASE_PATH_API,
  fetchApi: customFetchApi(fetch),
}))
export const AdminActionsApi = new AdminActionsApiClass(new Configuration({
  basePath: BASE_PATH_API,
  fetchApi: customFetchApi(fetch),
}))
