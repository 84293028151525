import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '@mui/material'
import './navigation.scss'
import { AppSetting } from '~shared/models/Model'

interface NavItemProps {
  item: AppSetting
  collapsed?: boolean
}

const NavigationItem = (props: NavItemProps) => {
  return (
    <NavLink
      className='navItem'
      activeClassName='selected'
      to={props.item.path}
    >
      {props.item.icon
        ? <div className='iconWrapper'>
          <Icon className='icon'>{props.item.icon}</Icon>
        </div>
        : <img width={50} height={50} src={props.item.img} alt='' />}
      {
        !props.collapsed &&
          <div className='navItem__text'>
            <p>{props.item.title}</p>
          </div>
      }
    </NavLink>
  )
}

export default NavigationItem
