import React, { useCallback, useEffect } from 'react'

import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import { UserDataDto, DoorInfoDto } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import { useDispatch, useSelector } from 'react-redux'
import { getDoor, getUser, onSuccess } from '~store/user/user.selector'
import { getDoorDataInitiated } from '~store/user/user.actions'

import TableViewDetail from '~shared/components/TableView/TableViewDetail'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
  }),
)

interface Props {
  user?: UserDataDto
  shortDoorId: string
}

const TechDoor: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const door: DoorInfoDto | undefined = useSelector(getDoor)

  const dispatch = useDispatch()
  const dispatchGetDoor = useCallback((shortDoorId) => dispatch(getDoorDataInitiated({ shortId: shortDoorId })), [dispatch])
  const onSaveSuccess = useSelector(onSuccess)

  useEffect(() => {
    if (props.user) {
      props.shortDoorId && dispatchGetDoor(props.shortDoorId)
    } else {
      history.push('/login')
    }
  }, [props.shortDoorId])

  useEffect(() => {
    if (onSaveSuccess === 'createDoorProtocol') {
      props.shortDoorId && dispatchGetDoor(props.shortDoorId)
    }
  }, [onSaveSuccess])

  useEffect(() => {
    door && setSelectedDoor(door)
  }, [door])

  const [selectedDoor, setSelectedDoor] = React.useState<DoorInfoDto | null>(null)

  return (
    <>
      {selectedDoor &&
        <div className={classes.root}>
          <TableViewDetail door={selectedDoor} />
        </div>}
    </>
  )
}
export default TechDoor
