import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Card,
  Divider,
  List,
  ListItem,
  Theme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

import { User } from '~shared/models/Model'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: 'fixed',
      top: 17,
      bottom: 'auto',
      left: 'auto',
      backgroundColor: 'white',
      minWidth: 170,
    },
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      fontSize: '14px!important',
      fontWeight: theme.typography.fontWeightRegular,
      padding: '7px',
      color: theme.palette.text.primary,
      borderRadius: '0px',
    },
  }),
)

export interface SettingsMenuProps<> {
  user: User
  showSettingsMenu: boolean
  setShowSettingsMenu: (show: boolean) => void
  doLogout: () => void
}

const SettingsMenu = (props: SettingsMenuProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const setShowSettingsMenu = props.setShowSettingsMenu
  const doLogout = props.doLogout

  const onSelectSettings = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push('/apps/settings')
    },
    [setShowSettingsMenu],
  )

  const onLogout = useCallback(
    () => {
      setShowSettingsMenu(false)
      doLogout()
    },
    [setShowSettingsMenu, doLogout],
  )
  if (!props.showSettingsMenu) {
    return (<div />)
  }
  return (
    <>
      {
        !props.showSettingsMenu
          ? <div />
          : (
            <Card
              className={classes.dialog}
            >
              <List>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectSettings}>
                    <SettingsIcon /> {t('common:settings')}
                  </IconButton>
                </ListItem>
                <li>
                  <Divider />
                </li>
                <ListItem>
                  <IconButton className={classes.button} onClick={onLogout}>
                    <PowerSettingsNewIcon /> {t('common:logout')}
                  </IconButton>
                </ListItem>
              </List>
            </Card>
          )
      }
    </>
  )
}

export default SettingsMenu
