import { call, delay, fork, put, take, takeEvery } from 'redux-saga/effects'
import { bindAsyncAction } from 'typescript-fsa-redux-saga'
import { Action } from 'typescript-fsa'
import { AdminActionsApi, DoorApi, SessionApi, UserDataApi, VpsApi } from '~api/instances'

import {
  loginInitiated,
  login,
  logout,
  logoutInitiated,
  forgotPassword,
  forgotPasswordInitiated,
  updatePassword,
  updatePasswordInitiated,
  resetPassword,
  resetPasswordInitiated,
  getUserData,
  getUserDataInitiated,
  updateUserData,
  updateUserDataInitiated,
  getVpsList,
  getVpsListInitiated,
  addToast,
  removeLastToast,
  resetUserSecret,
  resetUserSecretInitiated,
  getDoorData,
  getDoorDataInitiated,
  createDoorProtocol,
  createDoorProtocolInitiated,
  getDoorList,
  getDoorListInitiated,
  exportDoorListTechInitiated,
  exportDoorListTech,
  exportDoorListAdmin,
  exportDoorListAdminInitiated,
  importDoorListTechInitiated,
  importDoorListTech, importDoorListAdminInitiated, importDoorListAdmin, printDoorQRAdmin, printDoorQRAdminInitiated,
} from './user.actions'

import { LoginRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/models/index'
import {
  SessionForgotPasswordRequest,
  SessionResetPasswordRequest,
  SessionChangePasswordRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/SessionApi'
import { UpdateUserDataRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/UserDataApi'
import { ResetUserTwoFactorAuthSecretRequest } from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/AdminActionsApi'
import {
  AddDoorProtocolOperationRequest,
  ExportDoorExcelFileForTechUserRequest,
  ExportDoorExcelFileForAdminRequest,
  GetDoorRequest,
  ImportDoorExcelFileForAdminRequest,
  ImportDoorExcelFileForTechUserRequest, ExportDoorQrCodesOperationRequest,
} from '@beweng-security-solutions/beweng-cloud-solutions-frontend-api/apis/DoorApi'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

// login
const loginWorker = bindAsyncAction(login)(
  function * (params: LoginRequest) {
    return yield call([SessionApi, SessionApi.sessionLogin], { loginRequest: params })
  },
)

function * watchLoginInitiated () {
  while (true) {
    const action: Action<LoginRequest> = yield take(loginInitiated)
    try {
      yield loginWorker(action.payload)
    } catch (e) {
      yield put(addToast({
        type: Severity.error,
        translate: 'messages:loginError',
      }))
    }
  }
}

// logout
const logoutWorker = bindAsyncAction(logout)(
  function * () {
    return yield call([SessionApi, SessionApi.sessionLogout])
  },
)

function * watchLogoutInitiated () {
  while (true) {
    const action: Action<undefined> = yield take(logoutInitiated)
    try {
      yield logoutWorker(action.payload)
    } catch (e) {
      console.error('error in watchLogoutInitiated: ', e)
    }
  }
}

const forgotPasswordWorker = bindAsyncAction(forgotPassword)(
  function * (params: SessionForgotPasswordRequest) {
    return yield call([SessionApi, SessionApi.sessionForgotPassword], params)
  },
)

function * watchForgotPasswordInitiated () {
  while (true) {
    const action: Action<SessionForgotPasswordRequest> = yield take(forgotPasswordInitiated)

    try {
      yield forgotPasswordWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:forgotPasswordSuccess',
      }))
    } catch (e) {
      console.error('error in watchForgotPasswordInitiated: ', e)
    }
  }
}

const updatePasswordWorker = bindAsyncAction(updatePassword)(
  function * (params: SessionChangePasswordRequest) {
    return yield call([SessionApi, SessionApi.sessionChangePassword], params)
  },
)

function * watchUpdatePasswordInitiated () {
  while (true) {
    const action: Action<SessionChangePasswordRequest> = yield take(updatePasswordInitiated)
    try {
      yield updatePasswordWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:changePasswordSuccess',
      }))
    } catch (e) {
      console.error('error in watchUpdatePasswordInitiated: ', e)
      yield put(addToast({
        type: Severity.error,
        translate: 'messages:changePasswordError',
      }))
    }
  }
}

const resetPasswordWorker = bindAsyncAction(resetPassword)(
  function * (params: SessionResetPasswordRequest) {
    return yield call([SessionApi, SessionApi.sessionResetPassword], params)
  },
)

function * watchResetPasswordInitiated () {
  while (true) {
    const action: Action<SessionResetPasswordRequest> = yield take(resetPasswordInitiated)
    try {
      yield resetPasswordWorker(action.payload)
    } catch (e) {
      console.error('error in watchResetPasswordInitiated: ', e)
    }
  }
}

const getUserDataWorker = bindAsyncAction(getUserData)(
  function * () {
    return yield call([UserDataApi, UserDataApi.getUserData])
  },
)

function * watchGetUserDataInitiated () {
  while (true) {
    const action: Action<undefined> = yield take(getUserDataInitiated)

    try {
      yield getUserDataWorker(action.payload)
    } catch (e) {
      console.error('error in watchGetUserInitiated: ', e)
    }
  }
}

const updateUserDataWorker = bindAsyncAction(updateUserData)(
  function * (params: UpdateUserDataRequest) {
    return yield call([UserDataApi, UserDataApi.updateUserData], params)
  },
)

function * watchUpdateUserDataInitiated () {
  while (true) {
    const action: Action<UpdateUserDataRequest> = yield take(updateUserDataInitiated)
    try {
      yield updateUserDataWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:updateUserDataSuccess',
      }))
    } catch (e) {
      console.error('error in watchUpdateUserDataInitiated: ', e)
    }
  }
}

const getDoorListWorker = bindAsyncAction(getDoorList)(
  function * () {
    return yield call([DoorApi, DoorApi.listDoor])
  },
)

function * watchGetDoorListInitiated () {
  while (true) {
    const action: Action<undefined> = yield take(getDoorListInitiated)

    try {
      yield getDoorListWorker(action.payload)
    } catch (e) {
      console.error('error in watchGetDoorListInitiated: ', e)
    }
  }
}

const getDoorDataWorker = bindAsyncAction(getDoorData)(
  function * (params: GetDoorRequest) {
    return yield call([DoorApi, DoorApi.getDoor], params)
  },
)

function * watchGetDoorDataInitiated () {
  while (true) {
    const action: Action<GetDoorRequest> = yield take(getDoorDataInitiated)
    try {
      yield getDoorDataWorker(action.payload)
    } catch (e) {
      console.error('error in watchGetDoorDataInitiated: ', e)
    }
  }
}

const createDoorProtocolWorker = bindAsyncAction(createDoorProtocol)(
  function * (params: AddDoorProtocolOperationRequest) {
    return yield call([DoorApi, DoorApi.addDoorProtocol], params)
  },
)

function * watchCreateDoorProtocolInitiated () {
  while (true) {
    const action: Action<AddDoorProtocolOperationRequest> = yield take(createDoorProtocolInitiated)
    try {
      yield createDoorProtocolWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:createDoorProtocolSuccess',
      }))
    } catch (e) {
      console.error('error in watchUpdateUserDataInitiated: ', e)
    }
  }
}

const resetUserSecretWorker = bindAsyncAction(resetUserSecret)(
  function * (params: ResetUserTwoFactorAuthSecretRequest) {
    return yield call([AdminActionsApi, AdminActionsApi.resetUserTwoFactorAuthSecret], params)
  },
)

function * watchResetUserSecretInitiated () {
  while (true) {
    const action: Action<ResetUserTwoFactorAuthSecretRequest> = yield take(resetUserSecretInitiated)
    try {
      yield resetUserSecretWorker(action.payload)
    } catch (e) {
      yield put(addToast({
        type: Severity.error,
        translate: 'messages:deleteSecretError',
      }))
    }
  }
}

const getVpsListWorker = bindAsyncAction(getVpsList)(
  function * () {
    return yield call([VpsApi, VpsApi.listVps])
  },
)

function * watchGetVpsListInitiated () {
  while (true) {
    const action: Action<undefined> = yield take(getVpsListInitiated)

    try {
      yield getVpsListWorker(action.payload)
    } catch (e) {
      console.error('error in watchGetVpsListInitiated: ', e)
    }
  }
}

export function * watchAddToastSaga () {
  yield takeEvery(
    addToast.type,
    function * () {
      yield delay(15 * 1000)
      yield put(removeLastToast())
    },
  )
}

const exportDoorListTechWorker = bindAsyncAction(exportDoorListTech)(
  function * (params: ExportDoorExcelFileForTechUserRequest) {
    return yield call([DoorApi, DoorApi.exportDoorExcelFileForTechUser], params)
  },
)

function * watchExportDoorListTechInitiated () {
  while (true) {
    const action: Action<ExportDoorExcelFileForTechUserRequest> = yield take(exportDoorListTechInitiated)
    try {
      yield exportDoorListTechWorker(action.payload)
    } catch (e) {
      console.error('error in watchExportDoorListTechInitiated: ', e)
    }
  }
}

const printDoorQRAdminWorker = bindAsyncAction(printDoorQRAdmin)(
  function * (params: ExportDoorQrCodesOperationRequest) {
    return yield call([DoorApi, DoorApi.exportDoorQrCodes], params)
  },
)

function * watchPrintDoorQRAdminInitiated () {
  while (true) {
    const action: Action<ExportDoorQrCodesOperationRequest> = yield take(printDoorQRAdminInitiated)
    try {
      yield printDoorQRAdminWorker(action.payload)
    } catch (e) {
      console.error('error in watchPrintDoorQRAdminInitiated: ', e)
    }
  }
}
const exportDoorListAdminWorker = bindAsyncAction(exportDoorListAdmin)(
  function * (params: ExportDoorExcelFileForAdminRequest) {
    return yield call([DoorApi, DoorApi.exportDoorExcelFileForAdmin], params)
  },
)

function * watchExportDoorListAdminInitiated () {
  while (true) {
    const action: Action<ExportDoorExcelFileForAdminRequest> = yield take(exportDoorListAdminInitiated)
    try {
      yield exportDoorListAdminWorker(action.payload)
    } catch (e) {
      console.error('error in watchExportDoorListAdminInitiated: ', e)
    }
  }
}

const importDoorListTechWorker = bindAsyncAction(importDoorListTech)(
  function * (params: ImportDoorExcelFileForTechUserRequest) {
    return yield call([DoorApi, DoorApi.importDoorExcelFileForTechUser], params)
  },
)

function * watchImportDoorListTechInitiated () {
  while (true) {
    const action: Action<ImportDoorExcelFileForTechUserRequest> = yield take(importDoorListTechInitiated)
    try {
      yield importDoorListTechWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:importDoorExcelFileSuccess',
      }))
    } catch (e) {
      yield put(addToast({
        type: Severity.error,
        translate: 'messages:importDoorExcelFileError',
      }))
    }
  }
}

const importDoorListAdminWorker = bindAsyncAction(importDoorListAdmin)(
  function * (params: ImportDoorExcelFileForAdminRequest) {
    return yield call([DoorApi, DoorApi.importDoorExcelFileForAdmin], params)
  },
)

function * watchImportDoorListAdminInitiated () {
  while (true) {
    const action: Action<ImportDoorExcelFileForAdminRequest> = yield take(importDoorListAdminInitiated)
    try {
      yield importDoorListAdminWorker(action.payload)
      yield put(addToast({
        type: Severity.success,
        translate: 'messages:importDoorExcelFileSuccess',
      }))
    } catch (e) {
      yield put(addToast({
        type: Severity.error,
        translate: 'messages:importDoorExcelFileError',
      }))
    }
  }
}

export function * userSagas () {
  yield fork(watchLoginInitiated)
  yield fork(watchResetUserSecretInitiated)
  yield fork(watchAddToastSaga)
  yield fork(watchLogoutInitiated)
  yield fork(watchResetPasswordInitiated)
  yield fork(watchForgotPasswordInitiated)
  yield fork(watchUpdatePasswordInitiated)
  yield fork(watchGetUserDataInitiated)
  yield fork(watchUpdateUserDataInitiated)
  yield fork(watchGetDoorDataInitiated)
  yield fork(watchGetDoorListInitiated)
  yield fork(watchCreateDoorProtocolInitiated)
  yield fork(watchGetVpsListInitiated)
  yield fork(watchExportDoorListTechInitiated)
  yield fork(watchExportDoorListAdminInitiated)
  yield fork(watchImportDoorListTechInitiated)
  yield fork(watchImportDoorListAdminInitiated)
  yield fork(watchPrintDoorQRAdminInitiated)
}
