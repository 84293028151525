import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './locales'

const language = window.localStorage.getItem('language')
  ? window.localStorage.getItem('language') as string
  : 'en-US'

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
