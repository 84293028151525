import React from 'react'

import VpnKeyIcon from '@mui/icons-material/VpnKey'

import { createStyles, withStyles } from '@mui/styles'
import { Card, Avatar, List, Icon, ListItemText } from '@mui/material'

import { ListItemLink } from './ListItemLink'
import './style.scss'
import colors from '../../constants/colors.json'

const styles = () => createStyles({
  list: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60,
    backgroundColor: '#EFEFEF',

  },
  dashboardIcon: {
    fontSize: 24,
  },
})

interface IDashboardComponentAppProps extends WithStyles<typeof styles>{
  title: string
  path: string
  icon: string
  desc?: string
  img?: string
}

const DashboardComponent: React.FC<IDashboardComponentAppProps> = (props: IDashboardComponentAppProps) => {
  const { classes } = props
  return (
    <Card>
      <List className={classes.list}>
        <ListItemLink to={props.path}>
          <Avatar
            alt='Logo'
            className={`${classes.avatar} dashboard-icon icon-${props.icon}`}
          >
            {(props.icon === 'vpnkey')
              ? <VpnKeyIcon className={classes.dashboardIcon} style={{ color: colors.darkRed }} />
              : <Icon className={classes.dashboardIcon} style={{ color: colors.darkRed }}>{props.icon}</Icon>}
          </Avatar>
          <ListItemText primary={props.title} secondary={props.desc} className='dashboard-hover' />
        </ListItemLink>
        <br />
      </List>
    </Card>
  )
}

export default withStyles(styles)(DashboardComponent)
